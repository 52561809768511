import React from 'react'
import { Button, Card } from 'react-bootstrap'
import './cartcard.scss'
import { Link } from 'react-router-dom'

export const CartCard = (props) => {
    const { cartbookAuthor, cartPrice, cartImg, cartText1, cartText2, onClick, onCartClick, cat_name, pubDate, publisher, link_to,
        cartbookAuthor2
    } = props
    return (
        <>
            <Card className="cart_card">
                {cat_name && <p className='mb-2 p-2 bg-rel'>Related item in {cat_name}</p>}
                <Link to={link_to} className='category_img_div'>
                    <Card.Img variant="top" src={cartImg} className="img-fluid cartcard_img" onClick={() => onClick()} alt={`${cartbookAuthor} ${cartText1}`} />
                </Link>
                <div className="d-flex justify-content-between my-3">
                    <p className='mb-0' onClick={() => onClick()}>
                        <Link to={link_to} className='cart_book'>
                            {cartbookAuthor}</Link></p>
                    <p className='cartsymboldiv'><span className='cart_price_symbol'>&pound;</span>
                        <span className='cart_price'>{cartPrice}</span></p>
                </div>
                <div className='text_para mb-2'>
                    {/* {(publisher || pubDate) && <p className='card_text2 mb-2'>
                        <strong>{publisher} {pubDate ? `(${pubDate})` : ""}</strong>
                    </p>} */}
                    {(cartbookAuthor2) && <p className='card_text2 mb-2'>
                        <strong>{cartbookAuthor2}</strong>
                    </p>}
                    {cartText1 && <p className='card_text2 mb-2'>
                        <strong>{cartText1}</strong>
                    </p>}
                    <p className='card_text2'>
                        {cartText2}
                    </p>

                </div>
                <div className='text-end'>
                    <Button className='cart_button' onClick={() => onCartClick()}>Add to cart</Button>
                </div>
            </Card>
        </>
    )
}
