
import axios from 'axios'
import { baseUrl } from "./api.config"

const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
        'Content-Type': 'text/plain',
    },
})

axiosInstance.interceptors.response.use(
    (response) => {
        return new Promise((resolve, reject) => {
            resolve(response);
        })
    },
    async (error) => {
        return new Promise((resolve, reject) => {
            reject(error);
        });
    }
);

axiosInstance.interceptors.request.use(
    (request) => {
        return new Promise((resolve, reject) => {
            resolve(request);
        })
    },
    (error) => {
        if (!error.request) {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        }
    }
);

export default axiosInstance;