import React from 'react'
import { NavLink } from 'react-router-dom'
import { RouteStrings } from '../utils/RouteStrings'
import { Images } from '../utils/Images'

export const Page404 = () => {
    return (
        <div className='text-center my-5 pb-4'>
            <div className="container">
                <div>
                    <img src={Images.image404} alt="404" className='img-fluid' />
                </div>
                <h1 className='mb-0 class_404'>404</h1>
                <h5 className='mb-5'>Page not Found</h5>
                <NavLink to={RouteStrings.home} className={"cart_button text-decoration-none"}>Homepage</NavLink>
            </div>
        </div>
    )
}
