import React, { useEffect, useState } from 'react'
import "./trackorder.scss"
import { Button, Form } from 'react-bootstrap'
import { setOrderDetailStatus, setTrackOrderData } from '../../store/ui.reducer'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RouteStrings } from '../../utils/RouteStrings'
import { ApiResponseHook } from '../../apis/Services'
import { configUrl } from '../../apis/api.config'
import { Categorybar } from '../../components/CategoryBar/Categorybar'

export const TrackOrder = ({ categories }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { trackOrderdata } = useSelector((state) => state.localStorage)
    const { getRequest, postRequest } = ApiResponseHook()
    const [customerMail, setCustomermail] = useState({ mail: "", otp: "", orderNumber: "" })
    const [responseMsg, setResponseMsg] = useState({ error: false, msg: "", otpmsg: "" })
    const [trackingOrders, setTrackingOrders] = useState([])
    const [trackingOrderData, setTrackingOrderData] = useState([])
    const [filterTrackingdta, setFilterTrackingData] = useState({ fromdate: "", todate: "", transStatus: '', orderno: "" })
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageData, setPageData] = useState([]);

    useEffect(() => {
        handleTrackOrderData()
    }, [])
    useEffect(() => {
        handlePaginationData(trackingOrderData)
    }, [currentPage, trackingOrderData])

    const emailRegex = /^(.+)@(.+)$/;
    // get otp
    const getOtp = async (e, mail) => {
        e.preventDefault();
        if (emailRegex.test(mail)) {
            let response = await getRequest(`${configUrl.getOtp}${mail}/`)
            if (response?.status === 200) {
                setResponseMsg({ error: true, msg: "Verification code sent to your email" })
            } else if (response?.status === 404) {
                setResponseMsg({ error: false, msg: "Please check your Email, if new user, Order the Items" })
            } else {
                setResponseMsg({ error: false, msg: response?.data?.message })
            }
        } else {
            setResponseMsg({ error: false, msg: "Please enter Valid Email" })
        }
    }

    // calculate time after some time
    const handleCalculateTime = (timestamp, time) => {
        const originalDate = new Date(timestamp);
        originalDate.setMinutes(originalDate.getMinutes() + time);
        const resultTimestamp = originalDate.toISOString();
        return resultTimestamp
    }

    // get the trackOrderData for sometime
    const handleTrackOrderData = async () => {
        let currentTime = new Date().toISOString()
        if (trackOrderdata && (currentTime < handleCalculateTime(trackOrderdata.time, 120)) && trackOrderdata.email) {
            let response = await getRequest(`${configUrl.getTrackorderList}${trackOrderdata.email}/`)
            let responsedata = response?.data?.orderDetails ? response?.data?.orderDetails : []
            if (response?.status === 200) {
                sortedDate(responsedata)
                setCustomermail({ ...customerMail, mail: trackOrderdata.email })
            }
        } else {
            dispatch(setTrackOrderData(null))
        }
    }

    const getDate = (date) => {
        let _date = date.split(" ")[0]
        _date = new Date(_date).toLocaleDateString()
        return _date
    }

    // sort the data based on date
    const sortedDate = (data) => {
        const organizedData = groupOrdersByPackageId(data);
        organizedData.sort((a, b) => parseInt(b.package_id) - parseInt(a.package_id))
        const combinedPackageOrder = data.filter((order) => {
            const { package_id } = order.orderDetail;
            return !organizedData.some((groupedOrder) => {
                return groupedOrder.package_id === package_id
            })
        });
        combinedPackageOrder.push(...organizedData);
        combinedPackageOrder.sort((a, b) => {
            const dateA = extractDate(a);
            const dateB = extractDate(b);

            if (dateA && dateB) {
                if (dateA.getTime() !== dateB.getTime()) {
                    return dateB - dateA;
                } else {
                    return b.orderno - a.orderno;
                }
            } else if (dateA) {
                return -1;
            } else if (dateB) {
                return 1;
            }
            return 0;
        });

        setTrackingOrders(combinedPackageOrder)
        setTrackingOrderData(combinedPackageOrder)
    }
    const extractDate = (item) => {
        if (item.orderno && item.orderDetail?.ordate) {
            return new Date(getDate(item.orderDetail.ordate));
        } else if (item.package_id && item.packageDetail?.[0]?.packed_date) {
            return new Date(getDate(item.packageDetail[0].packed_date));
        }
        return null;
    };




    // verify otp
    const verifyOtp = async (e, otp) => {
        e.preventDefault();

        if (emailRegex.test(customerMail.mail) && otp) {
            let response = await getRequest(`${configUrl.verifyOtp}${customerMail.mail}/${otp}/`)
            let responsedata = response?.data?.orderDetails ? response?.data?.orderDetails : []
            if (response.status === 200) {
                sortedDate(responsedata)
                setCustomermail({ ...customerMail, otp: "" })
                setResponseMsg({ error: false, otpmsg: "" })
                dispatch(setTrackOrderData({ email: customerMail.mail, time: new Date() }))
            } else if (response?.status === 400) {
                setResponseMsg({ error: true, otpmsg: response?.data?.message })
            } else {
                setResponseMsg({ error: true, otpmsg: response?.data?.message })
            }
        } else if (!customerMail.mail) {
            setResponseMsg({ error: false, msg: "Please enter Valid Email" })
        } else {
            setResponseMsg({ error: true, otpmsg: "Please enter OTP" })
        }
    }

    //segregate data based on payment
    const handleFilterData = (e) => {
        e.preventDefault()
        setFilterTrackingData({ ...filterTrackingdta, orderno: '' })
        filterPaid()
        setCurrentPage(1)
    }


    //filter item
    const filterPaid = (orderNo) => {
        const filteredData = trackingOrders.filter((item) => {
            const itemDate = getDate(item.orderDetail.ordate);
            const dateStartRange = itemDate >= getDate(filterTrackingdta.fromdate);
            const dateEndRange = itemDate <= getDate(filterTrackingdta.todate);
            const matchesInvoiced = filterTrackingdta.transStatus === "" ? true
                : (filterTrackingdta.transStatus === "0" ? (item.orderDetail.status == "1" && ((item.orderDetail.transtatus === "") || (item.orderDetail.transtatus === "0"))) :
                    ((item.orderDetail.status == "1") &&
                        (((item.orderDetail.transtatus).toLowerCase() === "received") ||
                            ((item.orderDetail.transtatus).toLowerCase() === "success") || (item.orderDetail.transtatus === "1")))
                )
            if (filterTrackingdta.fromdate && filterTrackingdta.todate) {
                return (dateStartRange && dateEndRange) && matchesInvoiced;
            } else if (filterTrackingdta.fromdate) {
                return dateStartRange && matchesInvoiced;
            } else if (filterTrackingdta.todate) {
                return dateEndRange && matchesInvoiced;
            } else {
                return matchesInvoiced
            }
        });
        if (orderNo) {
            setCurrentPage(1)
            let _filteredData = filteredData.filter(number => {
                if (number.orderno) {
                    return number.orderno.includes(orderNo)
                } else if (number.package_id) {
                    return number.packageDetail[0].package_id.includes(orderNo)
                }
            })
            setTrackingOrderData(_filteredData)
        } else {
            setTrackingOrderData(filteredData)
        }
    }

    // navigate to the ordersummary page
    const handleViewDetail = (orderno, packageno) => {
        let _data = {}
        if (orderno) {
            _data = { orderno: orderno, email: customerMail.mail };
        } else if (packageno) {
            _data = { packageno: packageno, email: customerMail.mail };
        }
        // Encode the data to Base64
        const encodedData = btoa(JSON.stringify(_data));
        navigate(`${RouteStrings.ordersummary}?od=${encodedData}`)
        dispatch(setOrderDetailStatus(true))
    }



    // combined package data
    const groupOrdersByPackageId = (orders) => {
        const groupedOrders = {};

        orders.forEach((order) => {
            const { package_id } = order.orderDetail;

            if (package_id && package_id !== 'null') {
                if (!groupedOrders[package_id]) {
                    groupedOrders[package_id] = {
                        package_id,
                        orderDetail: order.orderDetail,
                        packageDetail: order.packageDetail,
                        combinedOrders: [],
                        totalItems: 0,
                        totalAmount: 0,
                    };
                }

                // Extract relevant order summary details
                const orderSummary = {
                    orderno: order.orderno,
                    orderSummary: order.orderSummary,
                };

                // Calculate total items and total amount for the package
                const totalItems = order.orderSummary.length;
                const totalAmount = order.orderSummary.reduce((acc, item) => acc + parseFloat(item.price), 0);

                groupedOrders[package_id].combinedOrders.push(orderSummary);
                groupedOrders[package_id].totalItems += totalItems;
                groupedOrders[package_id].totalAmount += totalAmount;

            }
        });

        return Object.values(groupedOrders);
    };

    // pagination and pagination data starts
    const pageSize = 10;
    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };
    const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    // get pagination data based on page
    const handlePaginationData = (data) => {
        let totalPages = Math.ceil(data.length / pageSize);
        setTotalPages(totalPages)

        const paginatedData = data.slice((currentPage - 1) * pageSize, currentPage * pageSize);
        setPageData(paginatedData)
    }

    return (
        <div className='trackorder'>
            <div className="container">
                <Categorybar categories={categories} subCategory={[]}
                    subcategoryVisible={false} />
                <div className='my-5 w-600'>
                    <h3 className='mb-3'>Track your order</h3>
                    <p className="mb-4">
                        Please enter your Email address to confirm your identity and receive details about your orders.
                    </p>
                    <div className='mb-2 position-relative'>
                        <Form autoComplete='off' className='d-flex align-items-end' onSubmit={(e) => getOtp(e, customerMail.mail)}>
                            <Form.Group className="" controlId="formBasicEmail">
                                <Form.Label className='mb-1'>Email</Form.Label>
                                <Form.Control type="text" placeholder='Enter your Email here' className='input_field emailfield'
                                    value={customerMail.mail}
                                    onChange={(e) => {
                                        setCustomermail({ ...customerMail, mail: e.target.value })
                                        setResponseMsg({ ...responseMsg, msg: "" })
                                    }} />
                            </Form.Group>
                            <Button className='otp_btn' type="submit">
                                Verify User
                            </Button>
                        </Form>
                        {responseMsg.msg && <Form.Text className={`${responseMsg.error ? "text-black" : "text-danger"}`}>
                            <strong>{responseMsg.msg}</strong>
                        </Form.Text>}
                    </div>
                    <div>
                        <Form className={`${responseMsg.error ? "" : "isVerify"}`} autoComplete='off' onSubmit={(e) => verifyOtp(e, customerMail.otp)}>
                            <Form.Group className="mb-4" controlId="formBasicOtp">
                                <Form.Label className='mb-1'>Enter Verification Code</Form.Label>
                                <div className="d-flex align-items-end">
                                    <Form.Control type="number" placeholder='' className='input_field otpfield' value={customerMail.otp}
                                        onChange={(e) => {
                                            setCustomermail({ ...customerMail, otp: (e.target.value).trim() })
                                            setResponseMsg({ ...responseMsg, otpmsg: "" })
                                        }}
                                    />
                                    {responseMsg.otpmsg && <Form.Text className="text-danger ms-2">
                                        <strong>{responseMsg.otpmsg}</strong>
                                    </Form.Text>}
                                </div>
                            </Form.Group>

                            <Button className='cart_button' type="submit">
                                Verify
                            </Button>
                        </Form>
                    </div>
                </div>
                {trackingOrders.length > 0 &&
                    <div className="orderlist">
                        <div className='w-600'>
                            <h3 className='mb-3'>Order list</h3>
                        </div>
                        <Form className='d-md-flex gap-3' onSubmit={(e) => handleFilterData(e)}>
                            <div className="d-flex gap-2 gap-md-3 mb-3">
                                <Form.Group className="">
                                    <Form.Label className='mb-1'>From</Form.Label>
                                    <Form.Control type="date" placeholder='' className='input_field w-140'
                                        onChange={(e) => setFilterTrackingData({ ...filterTrackingdta, fromdate: e.target.value })} />
                                </Form.Group>
                                <Form.Group className="">
                                    <Form.Label className='mb-1'>To</Form.Label>
                                    <Form.Control type="date" placeholder='' className='input_field w-140'
                                        onChange={(e) => setFilterTrackingData({ ...filterTrackingdta, todate: e.target.value })} />
                                </Form.Group>
                            </div>
                            <div className="d-flex gap-3 align-items-end mb-3">
                                <Form.Group >
                                    <Form.Label className='mb-1'>Payment status</Form.Label>
                                    <Form.Select className='input_field'
                                        onChange={(e) => setFilterTrackingData({ ...filterTrackingdta, transStatus: e.target.value })}>
                                        <option value="">All</option>
                                        <option value="0">Unpaid</option>
                                        <option value="1">Paid</option>
                                    </Form.Select>
                                </Form.Group>
                                <Button className='cart_button' type="submit">
                                    Submit
                                </Button>
                            </div>
                        </Form>
                        <div className="w-30">
                            <Form.Group>
                                <Form.Label className='mb-1'>Search by Order number</Form.Label>
                                <Form.Control type="number" placeholder='' className='input_field' value={filterTrackingdta.orderno}
                                    onChange={(e) => {
                                        setFilterTrackingData({ ...filterTrackingdta, orderno: e.target.value })
                                        filterPaid(e.target.value)
                                    }} />
                            </Form.Group>
                        </div>
                        <div className='orderlist_table'>
                            <table className='text-center w-100'>
                                <thead className='thead_bg'>
                                    <tr>
                                        <th>Order number</th>
                                        <th>Date</th>
                                        <th>No. of items</th>
                                        <th>Total order amount</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody className='tbody_bg'>
                                    {pageData.length > 0 ? <>{pageData.map((item, i) => {
                                        if (item.orderno) {
                                            return (<tr key={i}>
                                                <td>{item.orderno}</td>
                                                <td>{getDate(item.orderDetail.ordate)}</td>
                                                <td>{item.orderSummary.length}</td>
                                                <td>&pound; {item.grandTotal}</td>
                                                <td>{item.orderDetail.status == "0" ? "Order Cancelled" : ((item.orderDetail.invoiced === "0") ? "Waiting for Invoice" : <>{((item.orderDetail.invoiced === "1") && ((item.orderDetail.transtatus === "") || (item.orderDetail.transtatus === "0"))) ? "Payment Pending" : "Paid"}</>)}</td>
                                                <td>{item.orderDetail.status == "0" ? null :
                                                    <Button className='bg-transparent m-0 p-0 border-0 text-decoration-underline text-primary'
                                                        onClick={() => handleViewDetail(item.orderno)}>View Details</Button>}
                                                </td>
                                            </tr>)
                                        } else if (item.package_id) {
                                            return (<tr key={i}>
                                                <td>
                                                    <span>Pkg Id</span>
                                                    <span> {item.package_id}</span>
                                                </td>
                                                <td>{getDate(item.packageDetail[0].packed_date)}</td>
                                                <td>{item.totalItems}</td>
                                                <td>&pound; {Number(item.totalAmount) + Number(item.packageDetail[0].postage) + Number(item.packageDetail[0].pricechange)}</td>
                                                <td>{item.orderDetail.status == "0" ? "Order Cancelled" : ((item.orderDetail.invoiced === "0") ? "Waiting for Invoice" : <>{((item.orderDetail.invoiced === "1") && ((item.orderDetail.transtatus === "") || (item.orderDetail.transtatus === "0"))) ? "Payment Pending" : "Paid"}</>)}</td>
                                                <td>{item.orderDetail.status == "0" ? null :
                                                    <Button className='bg-transparent m-0 p-0 border-0 text-decoration-underline text-primary'
                                                        onClick={() => handleViewDetail("", item.package_id)}>View Details</Button>}
                                                </td>
                                            </tr>)
                                        }

                                    })}

                                    </> : <tr><td className='no_data'>No Data Found</td></tr>}
                                </tbody>
                            </table>
                        </div>
                        {pageData.length > 0 && totalPages !== 1 &&
                            <div className='d-flex gap-3 justify-content-center my-4'>
                                <button onClick={handlePrevPage} disabled={currentPage === 1} className='cart_button'>&lt;&lt; <span className='ms-1'>Previous</span></button>
                                <button onClick={handleNextPage} disabled={currentPage === totalPages} className='cart_button'><span className='me-1'>Next</span> &gt;&gt;</button>
                            </div>}
                    </div>}
            </div >
        </div >
    )
}
