
export const RouteStrings = {
    home: "/",
    cataloguelist: "/cataloguelist",
    productdetail: "/productdetail",
    aboutus: "/aboutus",
    contactus: "/contactus",
    viewbasket: "/checkout",
    trackorder: "/orderhistory",
    payment: "/makepayment",
    ordersummary: "/ordersummary",
    paymentStatus: "/payment",
}
