import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, Outlet, withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RouteStrings } from '../../utils/RouteStrings'
import { ApiResponseHook } from '../../apis/Services'
import { configUrl, protectedKey } from '../../apis/api.config'
import CryptoJS from 'crypto-js';
import { Images } from '../../utils/Images'
import { Categorybar } from '../../components/CategoryBar/Categorybar'

export const PaymentStatus = ({ categories }) => {
    const location = useLocation();
    const { paymentItem } = useSelector((state) => state.localStorage)
    const { postRequest } = ApiResponseHook()

    const [paymentStatus, setPaymentStatus] = useState(true)
    const [paymentData, setPaymentData] = useState({})

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        // Access query parameters
        let success = queryParams.get('status');
        let crypt = queryParams.get('crypt');
        if (success && crypt) {
            crypt = crypt.replace(/@/g, '')

            let decryptedString = decryptHexToString(crypt, protectedKey)
            if (decryptedString) {
                let decryptedStringObject = parseQueryString(decryptedString)
                initialTranscationdata(decryptedStringObject)
                setPaymentData(decryptedStringObject)
                if (success == "0") {
                    setPaymentStatus(false)
                } else {
                    setPaymentStatus(true)
                    updateTransactions()
                }
            }
        } else {
            setPaymentData({})
        }
    }, [])


    // decode the sagepay response
    const decryptHexToString = (encryptedHex, password) => {
        try {
            const key = CryptoJS.enc.Utf8.parse(password);
            const iv = CryptoJS.enc.Utf8.parse(password);

            // Parse the hex string to a WordArray
            const encryptedBytes = CryptoJS.enc.Hex.parse(encryptedHex);

            // Decrypt using AES in CBC mode with PKCS#5 padding
            const decrypted = CryptoJS.AES.decrypt(
                { ciphertext: encryptedBytes },
                key,
                {
                    iv: iv,
                    mode: CryptoJS.mode.CBC,
                    padding: CryptoJS.pad.Pkcs7
                }
            );

            // Convert decrypted WordArray to a UTF-8 string
            const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
            return decryptedString;
        } catch (err) {
            console.log("🚀 ~ file: PaymentStatus.js:71 ~ decryptHexToString ~ err:", err)
        }
    };

    // convert sagedata to object
    const parseQueryString = (str) => {
        const keyValuePairs = str.split('&');
        const result = {};

        keyValuePairs.forEach(pair => {
            const [key, value] = pair.split('=');
            result[key] = decodeURIComponent(value || '');
        });

        return result;
    }

    // // convert to unixtimestamp
    const handleUnixtimestamp = () => {
        const date = new Date();
        const unixTimestampInSeconds = Math.floor(date.getTime() / 1000);
        const _unixTimestampInSeconds = unixTimestampInSeconds.toString()
        return _unixTimestampInSeconds
    }

    // store the initial data of the transactions
    const initialTranscationdata = async (detail) => {
        let data = {
            "encode_data": paymentItem,
            "txcode": detail.VendorTxCode,
            "trantime": handleUnixtimestamp()
        }
        let response = await postRequest(configUrl.updateInitiateTransDetails, data)
        if (response?.status == 200) {
            handleUpdateTransDetails(detail)
        }
    }

    // store the details of the transactions after the payment
    const handleUpdateTransDetails = async (data) => {
        let transData = {
            "status": data.Status,
            "statusdetail": data.StatusDetail,
            "vpsTxid": data.VPSTxId,
            "Txauthno": data.TxAuthNo ? data.TxAuthNo : "",
            "amount": data.Amount,
            "avscv2": data.AVSCV2 ? data.AVSCV2 : "",
            "address": data.AddressResult ? data.AddressResult : "",
            "postcode": data.PostCodeResult ? data.PostCodeResult : "",
            "cv2Result": data.CV2Result ? data.CV2Result : "",
            "giftaid": data.GiftAid,
            "3dsecurestatus": "NOTCHECKED",
            "cavv": "",
            "cardtype": data.CardType ? data.CardType : "",
            "last4digits": data.Last4Digits ? `${data.Last4Digits}&ExpiryDate=${data.ExpiryDate}&DeclineCode=${data.DeclineCode}` : "",
            "trantime": handleUnixtimestamp(),
            "txcode": data.VendorTxCode
        }


        let response = await postRequest(configUrl.updatetransDetails, transData)
    }

    // upadate the success transaction details
    const updateTransactions = async () => {
        let response = await postRequest(configUrl.updateTransStatus, {
            "encode_data": paymentItem
        })

    }



    return (
        <div className='contactpage'>
            <div className="container">
                <div className="mb-5">
                    <Categorybar categories={categories} subCategory={[]}
                        subcategoryVisible={false} />

                    <div className='mt-4 text-center'>
                        {Object.keys(paymentData).length > 0 ?
                            <>
                                <h4 className={`mb-3 ${paymentStatus ? "text-success" : "text-danger"}`}>
                                    <img src={paymentStatus ? Images.paysuccessicon : Images.payfailicon} alt="icon" className='me-2 paystatusicon' />
                                    Payment {paymentStatus ? "Successful" : "failed"}</h4>
                                {paymentStatus ?
                                    <div className='text-center'>
                                        <h6>Your payment has been processed successfully and your order will be sent to you shortly.</h6>
                                        <p className='my-3'>
                                            <Link to={RouteStrings.trackorder} className='text-dark'><strong>Click here</strong></Link>  to go to <strong>Track Order</strong> </p>
                                    </div> : <>
                                        <p>
                                            A problem was encountered while collecting payment for this order.
                                        </p>
                                        <h5 className='text-danger mb-2'>Secure server response error</h5>
                                        <p>We cannot determine whether payment has been successfully received or not. Please <Link to={RouteStrings.contactus}>click here</Link> to contact us, and please include your order number () and the nature of the error (Secure server response error), and we will be in touch shortly to confirm whether payment has been received.
                                        </p>
                                    </>}
                            </>
                            : <>
                                <h4>No Data Found</h4>
                            </>}
                    </div>
                </div>
            </div>
            <Outlet />
        </div>
    )
}
