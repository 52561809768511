import { useDispatch } from "react-redux";
import axiosInstance from "./AxiosInstance";
import { setLoader } from "../store/ui.reducer";


export const ApiResponseHook = () => {
    const dispatch = useDispatch()

    const getRequest = async (url, data) => {
        dispatch(setLoader(true))
        try {
            const response = await axiosInstance.get(url, data);
            return response
        } catch (err) {
            return err.response
        } finally {
            dispatch(setLoader(false))
        }
    }
    const postRequest = async (url, data) => {
        dispatch(setLoader(true))
        try {
            const response = await axiosInstance.post(url, data);
            return response
        } catch (err) {
            return err.response
        } finally {
            dispatch(setLoader(false))
        }
    }

    return { getRequest, postRequest }
}