import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { RouteStrings } from '../utils/RouteStrings'
import { Home } from '../pages/Home/Home'
import { CatalogueList } from '../pages/CatalogueList/CatalogueList'
import { ProductDetail } from '../pages/ProductDetail/ProductDetail'
import { AboutUs } from '../pages/AboutUs/AboutUs'
import { ContactPage } from '../pages/ContactPage/ContactPage'
import { ScrollTop } from '../components/ScrollTop/ScrollTop'
import { ViewBasket } from '../pages/ViewBasket/ViewBasket'
import { TrackOrder } from '../pages/TrackOrder/TrackOrder'
import { OrderSummary } from '../pages/TrackOrder/OrderSummary'
import { PaymentStatus } from '../pages/TrackOrder/PaymentStatus'
import { Page404 } from '../components/Page404'

export const RouteCheck = ({ screenWidth, categories }) => {

    return (
        <>
            <ScrollTop />
            <Routes>
                <Route path={RouteStrings.home} element={<Home screenWidth={screenWidth} categories={categories} />} />
                <Route path={RouteStrings.cataloguelist} element={<CatalogueList screenWidth={screenWidth} categories={categories} />} />
                <Route path={RouteStrings.productdetail} element={<ProductDetail categories={categories} />} />
                <Route path={RouteStrings.aboutus} element={<AboutUs categories={categories} />} />
                <Route path={RouteStrings.contactus} element={<ContactPage categories={categories} />} />
                <Route path={RouteStrings.viewbasket} element={<ViewBasket categories={categories} />} />
                <Route path={RouteStrings.trackorder} element={<TrackOrder categories={categories} />} />
                <Route path={RouteStrings.ordersummary} element={<OrderSummary categories={categories} />} />
                <Route path={RouteStrings.paymentStatus} element={<PaymentStatus categories={categories} />} />
                <Route path={"*"} element={<Page404 />} />
            </Routes>
        </>
    )
}
