import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./header.scss";
import { NavLink } from "react-router-dom";
import { RouteStrings } from "../../utils/RouteStrings";
import { Images } from "../../utils/Images";
import { Badge } from "react-bootstrap";
import { useSelector } from "react-redux";

export const Header = ({ screenWidth }) => {
    const { basketOrder } = useSelector(state => state.localStorage)
    const { shopBehalfStatus, sbcparams } = useSelector(state => state.sessionStorage)

    const [navOpen, setNavOpen] = useState(false)

    const breakpoint = 767;

    const navToggle = (event, status) => {
        event.stopPropagation();
        if (screenWidth <= breakpoint) {
            setNavOpen(status)
        }
    }
    const navbarRef = useRef();

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (navOpen && navbarRef.current && !navbarRef.current.contains(event.target)) {
                navToggle(event, false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [navOpen]);

    return (
        <div>
            <Navbar expand="md" className="header_style">
                <Container fluid className="bor_bottom">

                    <NavLink id="nav_logo" to={navOpen ? "" : `${RouteStrings.home}${shopBehalfStatus ? `?sbofc=${sbcparams}` : ''}`}>
                        <img src={Images.logo} alt="logo" className="img-fluid logostyle" />
                    </NavLink>
                    <div className="mob_cart">
                        {screenWidth >= breakpoint ? null : (
                            <>
                                <NavLink to={RouteStrings.viewbasket} className="nav_item_btn">
                                    <img src={Images.cartImage} className="cart_icon" alt="" />
                                    {basketOrder.length > 0 && <Badge className="badge_style">{basketOrder.length}</Badge>}
                                </NavLink>
                            </>
                        )}
                        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={(e) => navToggle(e, true)} />
                        <Navbar.Collapse id="basic-navbar-nav" ref={navbarRef} className={`navbar_div ${navOpen ? "nav_open" : ""}`}>
                            <div className="px-3 mt-5 mb-4 px-md-0 my-md-0">
                                <div className="navbarclose d-md-none">
                                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={(e) => navToggle(e, false)} />
                                    <img src={Images.logowhite} alt="logo" className="img-fluid" />
                                </div>
                                <Nav className="ms-auto nav_div">
                                    <NavLink to={`${RouteStrings.home}${shopBehalfStatus ? `?sbofc=${sbcparams}` : ''}`} onClick={(e) => navToggle(e, false)} className="nav_item"><img src={Images.homeicon} alt="icon" className="close_icon img-fluid d-md-none me-2" /> Home</NavLink>
                                    <NavLink to={RouteStrings.aboutus} onClick={(e) => navToggle(e, false)} className="nav_item"><img src={Images.abouticon} alt="icon" className="close_icon img-fluid d-md-none me-2" /> About Us</NavLink>
                                    <NavLink to={RouteStrings.trackorder} onClick={(e) => navToggle(e, false)} className="nav_item"><img src={Images.trackicon} alt="icon" className="close_icon img-fluid d-md-none me-2" /> Track Order</NavLink>
                                    <NavLink to={RouteStrings.contactus} onClick={(e) => navToggle(e, false)} className="nav_item"><img src={Images.contacticon} alt="icon" className="close_icon img-fluid d-md-none me-2" /> Contact Us</NavLink>
                                    {screenWidth >= breakpoint ?
                                        <NavLink to={RouteStrings.viewbasket} className="nav_item_btn"><img src={Images.cartImage} className="cart_icon" alt="" /><span className="d-md-none d-lg-block">View Basket</span>
                                            {basketOrder.length > 0 && <Badge className="badge_style">{basketOrder.length}</Badge>}
                                        </NavLink>
                                        : null}
                                </Nav>
                                <div className='navfollow d-md-none'>
                                    <div className="text-center">
                                        <p className='text-white mb-2'>Follow us on</p>
                                        <a href="#"><img src={Images.fbicon} alt="icon" className='socialicon' /></a>
                                    </div>
                                </div>

                            </div>
                        </Navbar.Collapse>
                    </div>
                </Container>
            </Navbar>
        </div>
    );
};
