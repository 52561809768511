import React, { useEffect, useRef, useState } from 'react'
import './cataloguelist.scss'
import { Dropdown, Pagination } from 'react-bootstrap'
import { CartCard } from '../../components/CartCard/CartCard'
import { FilterBar } from '../../components/FilterBar/FilterBar'
import { RouteStrings } from '../../utils/RouteStrings'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ApiResponseHook } from '../../apis/Services'
import { configUrl } from '../../apis/api.config'
import {
    resetSearch,
    setActiveCatalogueType,
    setBasketOrder, setDropdownStatus, setProductDetail, setSearchDetails,
    setSearchStatus, setSubCategoryData, setToast
} from '../../store/ui.reducer'
import { Categorybar } from '../../components/CategoryBar/Categorybar'
import { RelateCard } from '../../components/CartCard/RelateCard'
import { Images } from '../../utils/Images'
import { Helmet } from 'react-helmet'

export const CatalogueList = ({ screenWidth, categories }) => {
    const productPositionRef = useRef(null)

    const { getRequest, postRequest } = ApiResponseHook()
    const { basketOrder, isDropdownStatus, subCategoryData,
        isSearch, searchDetail, activeCatalogueType } = useSelector((state) => state.localStorage)

    let _basketOrder = [...basketOrder]
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const [activeCatelogue, setActiveCatalogue] = useState([])
    const [subCategory, setSubCategories] = useState([])
    const [filterValue, setFilterValue] = useState("newlyUpdated")
    const [catalogueData, setCatalougueData] = useState({})
    console.log("🚀 ~ CatalogueList ~ catalogueData:", catalogueData)
    const [catalogueTypeDescription, setCatalogueTypeDescription] = useState({})
    const [pageNumber, setPageNumber] = useState(1)
    const [goto, setGoto] = useState('')
    const [buttons, setButtons] = useState([
        { id: 1, text: 'Newest items', isActive: true, apiValue: "newlyUpdated" },
        { id: 2, text: 'Author or date', isActive: false, apiValue: "author" },
        // { id: 3, text: 'Title', isActive: false, apiValue: "title" },
        { id: 4, text: 'High to low price', isActive: false, apiValue: "price_high" },
        { id: 5, text: 'Low to high price', isActive: false, apiValue: "price_low" },
    ]);
    const [filterButtonValue, setFilterButtonValue] = useState('')
    const [dropdownOptionsDescription, setDropdownOptionsDescription] = useState([])
    const [testActiveCatalogue, setTestActivecatlogue] = useState('1')
    const [activeSub, setActiveSub] = useState({})
    const filterArray = ['newlyUpdated', 'author', 'price_high', 'price_low']


    // get the details of items based on category,pageno,filtervalue
    const queryParams = new URLSearchParams(location.search);
    const pgno = queryParams.get('pg');
    const _pgno = pgno || 1;
    const ctvalue = queryParams.get('ft');
    const _ctValue = filterArray.includes(ctvalue) ? ctvalue : "newlyUpdated"
    const testact = queryParams.get('ct');
    const _testact = testact || "1"
    const paramSearch = queryParams.get('sch');
    const _paramSearch = paramSearch || ''
    const paramdrop = queryParams.get('sub');
    const _paramdrop = paramdrop || ''
    const schdesc = queryParams.get('desc') || '';
    useEffect(() => {
        let activeItem = categories.filter(item => item.category === _testact)
        setActiveCatalogue(activeItem)
        setTestActivecatlogue(_testact)
        setFilterValue(_ctValue)
        setPageNumber(Number(_pgno))
        getSubCategories(_testact)
        if (_paramSearch) {
            dispatch(setDropdownStatus(false))
            dispatch(setSearchStatus(true))
            setCatalogueTypeDescription({})
            setDropdownOptionsDescription([])
            handleSearchDetails({
                "term": _paramSearch,
                "adesc": Number(schdesc) || 0,
                "category_number": Number(_testact),
                "sortby": _ctValue,
                "page": _pgno
            })
        } else if (_paramdrop) {
            dispatch(setDropdownStatus(true))
            dispatch(setSearchStatus(false))
            _testact && getDropdowndata(_testact, _paramdrop, _ctValue, _pgno)
        } else {
            dispatch(setDropdownStatus(false))
            dispatch(setSearchStatus(false))
            _testact && getParticularCategoury(_testact, _ctValue, _pgno)
        }
        const updatedButtons = buttons.map((button) =>
            (button.apiValue == _ctValue)
                ? { ...button, isActive: true }
                : { ...button, isActive: false }
        );
        setButtons(updatedButtons);
        categoryDropdownMob(_ctValue)
    }, [_ctValue, _pgno, _testact, subCategoryData, _paramdrop, _paramSearch, schdesc])

    // get Catalogue subcategories type
    const getSubCategories = async (catId) => {
        if (catId && Number(catId) > 0) {
            let response = await getRequest(`${configUrl.getDropdownOptions}${catId}/`)
            setSubCategories(response?.data?.data ? response?.data?.data : [])
            if (paramdrop && response?.data?.data) {
                setActiveSub(getObjectByReference(response?.data?.data, paramdrop))
            }
        } else {
            setSubCategories([])
        }
    }

    //get dropdownDetails
    const getObjectByReference = (data, reference) => {
        for (const group of data) {
            for (const item of group.dropdownlist) {
                if (item.reference === reference) {
                    return item;
                }
            }
        }
        return {};
    }


    // get the particular category items 
    const getParticularCategoury = async (categoryId, value, page) => {
        let response = await getRequest(`${configUrl.getParticularCategoryData}${categoryId}/${value}/${page}/`)
        setCatalougueData(response?.data?.data ? response?.data?.data : {})
        setCatalogueTypeDescription(response?.data?.categorydescription ? response?.data?.categorydescription : {})
    }
    // get the category dropdownData items
    const getDropdowndata = async (categoryId, refernce, value, page) => {
        let response = await getRequest(`${configUrl.getSubcategoryData}${categoryId}/${refernce}/${value}/${page}/`)
        setCatalougueData(response?.data?.data ? response?.data?.data : {})
        if (isDropdownStatus) {
            setDropdownOptionsDescription(response?.data?.subcategory_header ? response?.data?.subcategory_header : [])
        } else {
            setCatalogueTypeDescription(response?.data?.categorydescription ? response?.data?.categorydescription : {})
        }
    }

    // search API
    const handleSearchDetails = async (data) => {
        let response = await postRequest(configUrl.getSearchDetails, data)
        let searchResponse = response?.data ? response?.data : {}
        delete searchResponse.status
        delete searchResponse.message
        setCatalougueData(searchResponse)
    }

    // filter for mobile view
    const categoryDropdownMob = (dropdownValue) => {
        let defaultvalue = buttons.filter(item => item.apiValue === dropdownValue)
        setFilterButtonValue(defaultvalue[0].apiValue)
    }

    // sorting/filter buttons function
    const handleButtonClick = (buttonId, buttonApiValue) => {
        if (_paramSearch) {
            navigate(`${RouteStrings.cataloguelist}?ft=${buttonApiValue}&ct=${testActiveCatalogue}&sch=${_paramSearch}&desc=${schdesc}`)
        } else if (_paramdrop) {
            navigate(`${RouteStrings.cataloguelist}?ft=${buttonApiValue}&ct=${testActiveCatalogue}&sub=${_paramdrop}`)
        } else {
            navigate(`${RouteStrings.cataloguelist}?ft=${buttonApiValue}&ct=${testActiveCatalogue}`)
        }
    };
    const _handleButtonClick = (buttonId, buttonApiValue) => {
        if (_paramSearch) {
            return `${RouteStrings.cataloguelist}?ft=${buttonApiValue}&ct=${testActiveCatalogue}&sch=${_paramSearch}&desc=${schdesc}`
        } else if (_paramdrop) {
            return `${RouteStrings.cataloguelist}?ft=${buttonApiValue}&ct=${testActiveCatalogue}&sub=${_paramdrop}`
        } else {
            return `${RouteStrings.cataloguelist}?ft=${buttonApiValue}&ct=${testActiveCatalogue}`
        }
    };

    // pagination section 
    const handlePageNumber = (id) => {
        if (id) {
            if (!(Number(id) > catalogueData.totalpages || Number(id) <= 0)) {
                if (_paramSearch) {
                    navigate(`${RouteStrings.cataloguelist}?ft=${filterValue}&pg=${id}&ct=${testActiveCatalogue}&sch=${_paramSearch}&desc=${schdesc}`)
                } else if (_paramdrop) {
                    navigate(`${RouteStrings.cataloguelist}?ft=${filterValue}&pg=${id}&ct=${testActiveCatalogue}&sub=${_paramdrop}`)
                } else {
                    navigate(`${RouteStrings.cataloguelist}?ft=${filterValue}&pg=${id}&ct=${testActiveCatalogue}`)
                }
                productPositionRef.current.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }


    // product detail of individual item 
    const handleProductDetail = (data) => {
        navigate(`${RouteStrings.productdetail}?item=${data.sysid}&ct=${testActiveCatalogue}&ft=${filterValue}&sch=${_paramSearch}&desc=${schdesc}&sub=${_paramdrop}&pg=${pageNumber}`)
    }
    const _handleProductDetail = (data) => {
        return `${RouteStrings.productdetail}?item=${data.sysid}&ct=${testActiveCatalogue}&ft=${filterValue}&sch=${_paramSearch}&desc=${schdesc}&sub=${_paramdrop}&pg=${pageNumber}`
    }

    // add to cart
    const handleCartClick = (data) => {
        if (_basketOrder.includes(data.sysid)) {
            dispatch(setToast(true))
        } else {
            _basketOrder.push(data.sysid)
            dispatch(setBasketOrder(_basketOrder))
            dispatch(setToast(true))
        }
    }

    // get the dropdown data
    const handleDropdownData = (data) => {
        dispatch(setDropdownStatus(true))
        dispatch(setSearchStatus(false))
        navigate(`${RouteStrings.cataloguelist}?ct=${testActiveCatalogue}&sub=${data.reference}`, { state: data })
    }
    const _handleDropdownData = (data) => {
        dispatch(setDropdownStatus(true))
        dispatch(setSearchStatus(false))
        // navigate(`${RouteStrings.cataloguelist}?ct=${testActiveCatalogue}&sub=${data.reference}`, { state: data })
    }
    // get the Related data
    const handleRelatedData = (data, categoryId) => {
        dispatch(setDropdownStatus(true))
        dispatch(setSearchStatus(false))
        productPositionRef.current.scrollIntoView({ behavior: 'smooth' })
        // navigate(`${RouteStrings.cataloguelist}?ct=${categoryId}&sub=${data.reference}`, { state: data })
    }
    // get the Related data
    const _handleRelatedData = (data, categoryId) => {
        return `${RouteStrings.cataloguelist}?ct=${categoryId}&sub=${data.reference}`
    }
    // get category name based on categoryId
    const getCategoryName = (id) => {
        let name = categories.filter(item => item.category === id)
        return name?.[0]?.name || ""
    }

    // for the metatags
    const getContent = () => {
        let data;
        if (isSearch) {
            data = "Search result for the term"
        } else if (dropdownOptionsDescription.length > 0 && Object.keys(dropdownOptionsDescription[0]).length > 0) {
            data = dropdownOptionsDescription[0].header
        } else {
            data = catalogueTypeDescription?.[0]?.category_description
        }
        data = data?.replace(/&nbsp;/g, '');
        data = data?.replace(/<\/?p>/g, '');
        data = data?.replace(/<\/?strong>/g, '');
        data = data?.replace(/<\/?i>/g, '');
        return data
    }

    const getContentName = (id) => {
        let _name;
        let name = categories.filter(item => item.category === id)
        name = name?.[0]?.name || ""
        if (isSearch) {
            _name = `Showing items that contain the search term ${(isSearch && _testact == "0") ? `${_paramSearch} in Whole Catalogue` : `${_paramSearch} in ${(activeCatelogue?.[0]?.name || getCategoryName(_testact))}`}`
        } else if (isDropdownStatus) {
            _name = `${name} ${_paramdrop === "346" ? "- W.I.P.S.G. publications" : (Object.keys(activeSub).length > 0 && `- ${activeSub?.thegroup || ''} - ${activeSub?.name}`)} `
        } else {
            _name = name
        }
        return _name
    }

    const getLink = () => {
        let link = "https://pennymead.com/cataloguelist?"
        if (_paramSearch) {
            link = `${link}ft=${_ctValue}&pg=${_pgno}&ct=${_testact}&sch=${_paramSearch}&desc=${schdesc}`
        } else if (_paramdrop) {
            link = `${link}ft=${_ctValue}&pg=${_pgno}&ct=${_testact}&sub=${_paramdrop}`
        } else {
            link = `${link}ft=${_ctValue}&pg=${_pgno}&ct=${_testact}`
        }
        return link
    }

    return (
        <div className='catalogue_page'>
            <Helmet>
                <meta name="description"
                    content={catalogueTypeDescription.length > 0 && Object.keys(catalogueTypeDescription[0]).length > 0 &&
                        getContent()} />
                <link rel="canonical" href={`${getLink()}`} />
                <title>{getContentName(testActiveCatalogue)}</title>
            </Helmet >
            <div className="container">
                <div className="mb-5">
                    <Categorybar categories={categories} subCategory={subCategory}
                        activeCatalogueType={testActiveCatalogue} subcategoryVisible={true} />
                </div>

                <div ref={productPositionRef}>
                    <div className='search_div'>
                        {isSearch ? <>
                            <h4>Showing items that contain the search term <strong>{(isSearch && _testact == "0") ? <>{`"${_paramSearch}" in Whole Catalogue`}</> : <>{`"${_paramSearch}"`} in {(activeCatelogue?.[0]?.name || getCategoryName(_testact))}</>}</strong></h4></> : <>
                            <h2>{(activeCatelogue?.[0]?.name || getCategoryName(_testact))} <strong className='f-20'>{isDropdownStatus && <>{_paramdrop === "346" ? "- W.I.P.S.G. publications" : (Object.keys(activeSub).length > 0 && `- ${activeSub?.thegroup || ''} - ${activeSub?.name}`)}</>}</strong>
                                <strong className='f-20'>{isSearch && `- ${_paramSearch}`}</strong></h2></>}

                        <div className='mb-5 mt-3'>
                            {isDropdownStatus ? <>
                                {dropdownOptionsDescription.length > 0 && Object.keys(dropdownOptionsDescription[0]).length > 0 && <>
                                    <div dangerouslySetInnerHTML={{ __html: dropdownOptionsDescription[0].header }} />
                                </>}</> : <>
                                {catalogueTypeDescription.length > 0 && Object.keys(catalogueTypeDescription[0]).length > 0 &&
                                    <>
                                        <div dangerouslySetInnerHTML={{ __html: catalogueTypeDescription[0].category_description.replace(/<p>&nbsp;<\/p>/g, '') }} />
                                    </>}
                            </>}
                            {(testActiveCatalogue == 1) ? <div className='mt-3'>
                                {_paramdrop && _paramdrop == 346 ? null :
                                    <p className="mb-0">To view the Philatelic Books of
                                        {/* <strong className='cursor-pointer text-decoration-underline' onClick={() => handleDropdownData({ name: "W.I.P.S.G. publications", reference: "346" })}> W.I.P.S.G. publications, click here</strong> */}
                                        <Link to={`${RouteStrings.cataloguelist}?ct=${testActiveCatalogue}&sub=346`} onClick={() => _handleDropdownData({ name: "W.I.P.S.G. publications", reference: "346" })} className='ms-2 text-dark'><strong>W.I.P.S.G. publications, click here</strong> </Link>
                                    </p>
                                }</div> : null}
                        </div>
                    </div>

                    {(Object.keys(catalogueData).length > 0 && (catalogueData.data ? catalogueData.data.length > 0 : catalogueData?.searchresults?.length > 0)) ?
                        <>
                            <div className="filter_sec mt-5 mb-4">
                                <FilterBar buttons={buttons} onClick={_handleButtonClick} value={filterButtonValue} />
                            </div>
                            <div className="row">
                                <div className={`${isDropdownStatus ? "col-md-8 col-lg-9" : "col-12"}`}>
                                    <div className={`${isDropdownStatus ? "pe-lg-5" : ""}`}>
                                        <div className='row'>
                                            {(isSearch ? catalogueData.searchresults : catalogueData.data) && (isSearch ? catalogueData.searchresults : catalogueData.data).map((item, i) => {
                                                return (
                                                    <div className={`${isDropdownStatus ? "col-md-6 col-xl-4 my-3 my-md-4" : "col-md-4 col-xl-3 my-3 my-md-4"}`} key={i}>
                                                        <CartCard cartImg={(item.image && item.image.length > 0) ? item.image[0] : Images.emptyImage} cartbookAuthor={item.author} cartPrice={item.price}
                                                            cartText1={item.title} cartText2={item.description} onClick={() => { }}
                                                            link_to={_handleProductDetail(item)} cartbookAuthor2={item.author2}
                                                            onCartClick={() => handleCartClick(item)} pubDate={item.pubdate} publisher={item.publisher} />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                {isDropdownStatus &&
                                    <div className="col-md-4 col-lg-3">
                                        <div className='related_div my-4'>
                                            {(catalogueData.relateddata && catalogueData.relateddata.length > 0) ? <>
                                                {catalogueData.relateddata.map((item, i) => {
                                                    return (
                                                        <RelateCard key={i} cartImg={item.image.length > 0 && item.image[0]} cartbookAuthor={item.author} cartPrice={item.price}
                                                            cat_name={getCategoryName(item.category)} cartText1={item.title} cartText2={item.description}
                                                            onClick={() => handleRelatedData({ name: item.keyword_name, reference: item.referenceId, thegroup: item.thegroup }, item.category)}
                                                            link_to={_handleRelatedData({ name: item.keyword_name, reference: item.referenceId, thegroup: item.thegroup }, item.category)} />
                                                    )
                                                })}</> : <p className='p-4'><strong>Related Items unavailable </strong></p>}
                                        </div>
                                    </div>}
                            </div>


                            <div className='pagination_style d-lg-flex'>
                                <Pagination>
                                    <Pagination.Prev
                                        onClick={(e) => handlePageNumber(pageNumber - 1)}
                                        disabled={catalogueData.hasprevious ? (catalogueData.hasprevious ? false : true) : true} />
                                    {screenWidth > 767 ?
                                        <>
                                            {pageNumber >= 4 ? <>
                                                <Pagination.Item id={"1"} onClick={(e) => handlePageNumber(e.target.id)}>{1}</Pagination.Item>
                                                {pageNumber !== 4 && <Pagination.Ellipsis />}
                                                <Pagination.Item id={pageNumber - 2} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber - 2}</Pagination.Item>
                                                <Pagination.Item id={pageNumber - 1} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber - 1}</Pagination.Item></> :
                                                <>{pageNumber == 3 ? <><Pagination.Item id={pageNumber - 2} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber - 2}</Pagination.Item>
                                                    <Pagination.Item id={pageNumber - 1} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber - 1}</Pagination.Item></> : <>
                                                    {pageNumber == 2 ? <Pagination.Item id={pageNumber - 1} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber - 1}</Pagination.Item> : null}</>}
                                                </>}
                                            <Pagination.Item active>{pageNumber}</Pagination.Item>
                                            <>
                                                {pageNumber >= (catalogueData.totalpages - 3) ? <>
                                                    {pageNumber <= (catalogueData.totalpages - 1) && <Pagination.Item id={pageNumber + 1} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber + 1}</Pagination.Item>}
                                                    {pageNumber <= (catalogueData.totalpages - 2) && <Pagination.Item id={pageNumber + 2} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber + 2}</Pagination.Item>}
                                                    {pageNumber == (catalogueData.totalpages - 3) && <Pagination.Item id={catalogueData.totalpages} onClick={(e) => handlePageNumber(e.target.id)}>{catalogueData.totalpages}</Pagination.Item>}

                                                </> : <>
                                                    <Pagination.Item id={pageNumber + 1} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber + 1}</Pagination.Item>
                                                    <Pagination.Item id={pageNumber + 2} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber + 2}</Pagination.Item>
                                                    <Pagination.Ellipsis />
                                                    <Pagination.Item id={catalogueData.totalpages} onClick={(e) => handlePageNumber(e.target.id)}>{catalogueData.totalpages}</Pagination.Item>
                                                </>}
                                            </>
                                        </> :
                                        <>
                                            {pageNumber >= 3 ? <>
                                                <Pagination.Item id={"1"} onClick={(e) => handlePageNumber(e.target.id)}>{1}</Pagination.Item>
                                                {catalogueData.totalpages > 3 && <Pagination.Ellipsis />}
                                                {pageNumber >= (catalogueData.totalpages - 1) &&
                                                    <Pagination.Item id={pageNumber - 1} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber - 1}</Pagination.Item>
                                                }
                                            </> :
                                                <>{pageNumber == 2 &&
                                                    <Pagination.Item id={pageNumber - 1} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber - 1}</Pagination.Item>
                                                }</>
                                            }
                                            <Pagination.Item active>{pageNumber}</Pagination.Item>
                                            <>
                                                {pageNumber >= (catalogueData.totalpages - 1) ? <>
                                                    {pageNumber == (catalogueData.totalpages - 1) && <Pagination.Item id={catalogueData.totalpages} onClick={(e) => handlePageNumber(e.target.id)}>{catalogueData.totalpages}</Pagination.Item>}

                                                </> : <>
                                                    {pageNumber < 3 &&
                                                        <Pagination.Item id={pageNumber + 1} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber + 1}</Pagination.Item>
                                                    }
                                                    {catalogueData.totalpages > 3 &&
                                                        <Pagination.Ellipsis />}
                                                    <Pagination.Item id={catalogueData.totalpages} onClick={(e) => handlePageNumber(e.target.id)}>{catalogueData.totalpages}</Pagination.Item>
                                                </>}
                                            </>
                                        </>}

                                    <Pagination.Next onClick={(e) => handlePageNumber((pageNumber + 1))}
                                        disabled={catalogueData.hasnext ? (catalogueData.hasnext ? false : true) : true} />
                                </Pagination>
                                {catalogueData.totalpages > 1 &&
                                    <div className='gopage_div'>
                                        <p>Go to page</p>
                                        <form onSubmit={(e) => e.preventDefault()}>
                                            <input type="number" value={goto} min="1" className='gopage' required onChange={(e) => setGoto(e.target.value)} />
                                            <button type='button' className='bg-transparent p-0' onClick={() => {
                                                handlePageNumber(goto)
                                                setGoto('')
                                            }}>Go &gt;</button>
                                        </form>
                                    </div>}
                            </div>
                        </>
                        : <h5>No Data Found</h5>}

                    <>
                        {isDropdownStatus ? <div className='my-5'>
                            {dropdownOptionsDescription.length > 0 && Object.keys(dropdownOptionsDescription[0]).length > 0 && <>
                                {dropdownOptionsDescription[0].footer && <div dangerouslySetInnerHTML={{ __html: dropdownOptionsDescription[0].footer }} />}
                            </>}</div> : <div className='my-5'>
                            {catalogueTypeDescription.length > 0 && Object.keys(catalogueTypeDescription[0]).length > 0 &&
                                <>
                                    <div className='mb-3' dangerouslySetInnerHTML={{ __html: catalogueTypeDescription[0].category_footer.replace(/<p>&nbsp;<\/p>/g, '') }} />
                                </>}
                        </div>}
                    </>
                </div>

            </div>
        </div >
    )
}
