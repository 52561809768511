import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { uiReducer, uiSessionReducer } from './ui.reducer'
import storage from "redux-persist/lib/storage"
import { persistReducer } from 'redux-persist'
import thunk from "redux-thunk"
import sessionStorage from 'redux-persist/es/storage/session'

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['toast']
}

const sessionStoragePersistConfig = {
    key: 'session',
    storage: sessionStorage,
};

const persist = combineReducers({
    localStorage: persistReducer(persistConfig, uiReducer.reducer),
    sessionStorage: persistReducer(sessionStoragePersistConfig, uiSessionReducer.reducer),
});


export const store = configureStore({
    reducer: persist,
    middleware: [thunk],
})


