
import React, { useEffect, useRef, useState } from 'react'
import './viewbasket.scss'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveCatalogueType, setBasketOrder, setOrderDetailStatus, setProductDetail, setToast } from '../../store/ui.reducer'
import { RouteStrings } from '../../utils/RouteStrings'
import { ApiResponseHook } from '../../apis/Services'
import { configUrl } from '../../apis/api.config'
import { Images } from '../../utils/Images'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { CartCard } from '../../components/CartCard/CartCard'
import { Categorybar } from '../../components/CategoryBar/Categorybar'
import { Helmet } from 'react-helmet'

export const ViewBasket = ({ categories }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const fieldErrorRef = useRef(null);
    const stateRef = useRef(null);

    const { basketOrder } = useSelector((state) => state.localStorage)
    const { shopBehalfStatus, shopbehalfData } = useSelector(state => state.sessionStorage)
    let customerEmail = shopbehalfData ? JSON.parse(atob(shopbehalfData)) : ''

    let _basketOrder = [...basketOrder]
    const { getRequest, postRequest } = ApiResponseHook()

    const [order, setOrder] = useState(false)
    const [basketItems, setBasketItems] = useState([]);
    const [countryList, setcountryList] = useState([]);
    const [collectableItems, setCollectableItems] = useState([]);
    const [holidayNote, setHolidayNote] = useState([])
    const [validated, setValidated] = useState(false);
    const [customerDetails, setCustomerDetails] = useState({
        title: "", forename: "", surname: "", email: customerEmail, address1: "", address2: "", county: "", town: "",
        postcode: "", country: "", payment: "", hphone: "", message: "", promotional_emails: false
    });
    const [inactiveMsg, setInactiveMsg] = useState('')

    useEffect(() => {
        getCountries()
        getHolidayNote()
    }, [])
    useEffect(() => {
        getCustomerDetails(customerEmail)
    }, [customerEmail])
    useEffect(() => {
        if (basketOrder.length > 0) {
            getBasketOrders({ basketItems: basketOrder })
        }
    }, [basketOrder])

    // get the details of basketOrders
    const getBasketOrders = async (data) => {
        let response = await postRequest(configUrl.getBasketData, data)
        let _basketOrder = response?.data?.result ? response?.data?.result : []
        _basketOrder = _basketOrder.map(item => {
            if (item.quantity == 0) {
                handleDeleteItem(item)
            }
            return item
        })
        setBasketItems(_basketOrder)
        setCollectableItems(response?.data?.collectableItems ? response?.data?.collectableItems : [])
        if (response?.status === 404) {
            dispatch(setBasketOrder([]))
        }

    }

    // get the list of countries
    const getCountries = async () => {
        let response = await getRequest(configUrl.getCountries)
        setcountryList(response?.data?.data ? response?.data?.data : [])
    }
    // get holiday note 
    const getHolidayNote = async () => {
        let holidayResponse = await getRequest(configUrl.getHolidayNote)
        setHolidayNote(holidayResponse?.data?.data ? holidayResponse?.data?.data : [])
    }

    // remove the items from checkout page 
    const handleDeleteItem = (data) => {
        let _basketOrder = basketOrder.filter(item => item != data.sysid)
        dispatch(setBasketOrder(_basketOrder))
    }

    // form validation
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        let validateData = false
        if (!form.checkValidity()) {
            validateData = true
            if (!customerDetails.email) {
                fieldErrorRef.current.scrollIntoView({ behavior: 'smooth' });
            } else if (!customerDetails.forename) {
                fieldErrorRef.current.scrollIntoView({ behavior: 'smooth' });
            } else if (!customerDetails.address1) {
                fieldErrorRef.current.scrollIntoView({ behavior: 'smooth' });
            } else if (!customerDetails.town) {
                fieldErrorRef.current.scrollIntoView({ behavior: 'smooth' });
            } else if (!customerDetails.hphone) {
                fieldErrorRef.current.scrollIntoView({ behavior: 'smooth' });
            } else if (!customerDetails.postcode) {
                fieldErrorRef.current.scrollIntoView({ behavior: 'smooth' });
            } else if (!customerDetails.country) {
                fieldErrorRef.current.scrollIntoView({ behavior: 'smooth' });
            } else if (!customerDetails.payment) {
                fieldErrorRef.current.scrollIntoView({ behavior: 'smooth' });
            }

        } else {
            // setOrder(true)
            validateData = false
            let _basketItems = basketItems.map(item => {
                return { sysid: item.sysid, pricePerItem: item.price }
            })
            let registerData = {
                ...customerDetails, name: (customerDetails.forename).trim(), hphone: Number(customerDetails.hphone),
                promotional_emails: customerDetails.promotional_emails == false ? 0 : 1,
                items: _basketItems
            }
            registerCustomer(registerData)
        }
        setValidated(validateData);
    };

    // product detail of individual item 
    const handleProductDetail = (data) => {
        navigate(`${RouteStrings.productdetail}?bask=1&ct=${data.category}&item=${data.sysid}`)
    }

    // add to cart
    const handleCartClick = (data) => {
        if (!(_basketOrder.includes(data.sysid))) {
            _basketOrder.push(data.sysid)
            dispatch(setBasketOrder(_basketOrder))
        }
        dispatch(setToast(true))
    }

    // get customer details based on email
    const emailRegex = /^(.+)@(.+)$/;
    const getCustomerDetails = async (data) => {
        let emptyDetail = {
            ...customerDetails,
            title: "", forename: "", surname: "", address1: "", address2: "", county: "", town: '',
            postcode: "", country: "", payment: "", hphone: '', message: "", promotional_emails: false
        }
        if (emailRegex.test(data)) {
            let response = await getRequest(`${configUrl.getCustomerDetails}${data}/`)
            let detail = response?.data?.data?.[0] ? response.data.data[0] : {}
            if (Object.keys(detail).length > 0) {
                if (detail.status === "1") {
                    let _detail = {
                        ...customerDetails,
                        forename: `${Object.keys(detail).length > 0 ? (`${detail.forename} ${detail.name}`).trim() : ""}`,
                        address1: detail.address1 ? detail.address1 : "",
                        address2: detail.address2 ? detail.address2 : "",
                        hphone: detail.hphone ? Number(detail.hphone) : '',
                        county: detail.county ? detail.county : "",
                        postcode: detail.postcode ? detail.postcode : "",
                        country: detail.country ? detail.country : "",
                        postcode: detail.postcode ? detail.postcode : "",
                        town: detail.town ? detail.town : '',
                        promotional_emails: detail.promotional_emails ? (detail.promotional_emails == "0" ? false : true) : false
                    }
                    setCustomerDetails(_detail)
                } else {
                    setCustomerDetails(emptyDetail)
                    setInactiveMsg("Your account associated with this email address is currently inactive. Please provide a new email address or reach out to the Admin for further assistance.")
                }
            } else {
                setInactiveMsg('')
                setCustomerDetails(emptyDetail)
            }
        }
    }

    // register customer
    const registerCustomer = async (data) => {
        let response = await postRequest(configUrl.registerCustomer, data)
        if (response.status === 200) {
            dispatch(setBasketOrder([]))
            dispatch(setOrderDetailStatus(false))
            let _data = { orderno: response?.data.ordernumber, email: response?.data.customerEmailid };
            // Encode the data to Base64
            const encodedData = btoa(JSON.stringify(_data));
            navigate(`${RouteStrings.ordersummary}?od=${encodedData}`)
        }
    }

    return (
        <div className='viewbasket'>
            <Helmet>
                <meta name="description"
                    content="Your Basket contains items which are added earlier as your interest" />
                <link rel="canonical" href="https://pennymead.com/checkout" />
                <title>View Basket</title>
            </Helmet>
            <div className="container">
                <div className="mb-5">
                    <Categorybar categories={categories} subCategory={[]}
                        subcategoryVisible={false} />

                    {holidayNote.length > 0 &&
                        <marquee behavior="scroll" direction="left" scrollamount="10" className="text-danger">
                            <strong>{`Please note I am away on holiday until ${new Date(holidayNote[0].end).toLocaleDateString()}. You are welcome to place orders during this time but they will not be processed until after my return on ${new Date(holidayNote[0].end).toLocaleDateString()}${holidayNote[0].message ? `, ${holidayNote[0].message}` : ""}.`}</strong></marquee>
                    }
                    {basketOrder.length > 0 ?
                        <div className='basketdata'>
                            <h2>{order ? "Order Summary" : "Check out"}</h2>

                            <div className="row mt-4">
                                <div className="col-md-6 col-lg-7 mb-4 mb-md-0">
                                    <div className="order_div">
                                        <div>
                                            {basketItems.map((item, i) => {
                                                return (
                                                    <div className="card checkout_card" key={i}>
                                                        <div className="row">
                                                            <div className="col-md-4 col-lg-3 mb-3 mb-md-0">
                                                                <div className='text-center'>
                                                                    <img src={(item.image && item.image.length > 0) ? item.image[0] : Images.emptyImage} className="img-fluid cart_img" alt={`${item.author} ${item.title}`} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-8 col-lg-9">
                                                                <div className="">
                                                                    <div className="d-flex justify-content-between align-items-end mb-3">
                                                                        <h5 className=''>{item.author}</h5>
                                                                        <h5><span>&pound;</span> <span>{item.price}</span></h5>
                                                                    </div>
                                                                    <p className='mb-2'><strong>{item.publisher} {item.pubdate && `(${item.pubdate})`}</strong></p>
                                                                    <p className='mb-2'>{item.title}</p>
                                                                    <p>{item.description}</p>

                                                                    {order ? null : <div className='text-end mt-4'>
                                                                        <Button className='remove_button' onClick={() => handleDeleteItem(item)}>
                                                                            <img src={Images.deleteIcon} alt="remove" className='img-fluid' />
                                                                        </Button>
                                                                    </div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {item.quantity == 0 ? <p className="text-danger mb-0 text-center">Item Not Available, Please delete Item from Basket</p> : null}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5">
                                    <div className='user_form'>
                                        <h5 className="mb-3">Your details</h5>
                                        {inactiveMsg && <p className='text-danger'>{inactiveMsg}</p>}
                                        <div>
                                            <Form noValidate validated={validated} onSubmit={handleSubmit} ref={fieldErrorRef} autoComplete='off'>
                                                <Row className="g-2">
                                                    <Form.Group as={Col} md="6" controlId="validationCustom01" className='form_group'>
                                                        <Form.Label>Email <span className='darkred'>&#42;</span></Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="email"
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                setCustomerDetails({ ...customerDetails, email: e.target.value })
                                                                setInactiveMsg('')
                                                            }}
                                                            value={customerDetails.email}
                                                            onBlur={(e) => getCustomerDetails(e.target.value)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Enter valid Email</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="validationCustom02" className='form_group'>
                                                        <Form.Label>Name <span className='darkred'>&#42;</span></Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            placeholder=""
                                                            onChange={(e) => setCustomerDetails({ ...customerDetails, forename: e.target.value })}
                                                            value={customerDetails.forename}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Enter Name</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="validationCustom03" className='form_group'>
                                                        <Form.Label>Address line 1 <span className='darkred'>&#42;</span></Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            placeholder=""
                                                            onChange={(e) => setCustomerDetails({ ...customerDetails, address1: e.target.value })}
                                                            value={customerDetails.address1}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Enter address</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="validationCustom04" className='form_group'>
                                                        <Form.Label>Address line 2</Form.Label>
                                                        <Form.Control
                                                            // required
                                                            type="text"
                                                            placeholder=""
                                                            onChange={(e) => setCustomerDetails({ ...customerDetails, address2: e.target.value })}
                                                            value={customerDetails.address2}
                                                        />
                                                        {/* <Form.Control.Feedback type="invalid">Enter address</Form.Control.Feedback> */}
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="validationCustom03" className='form_group'>
                                                        <Form.Label>Town/City<span className='darkred'>&#42;</span></Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            placeholder=""
                                                            onChange={(e) => setCustomerDetails({ ...customerDetails, town: e.target.value })}
                                                            value={customerDetails.town}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Enter town</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="validationCustom05" className='form_group'>
                                                        <Form.Label>Phone number <span className='darkred'>&#42;</span></Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="number"
                                                            placeholder=""
                                                            onChange={(e) => setCustomerDetails({ ...customerDetails, hphone: e.target.value.toString() })}
                                                            value={customerDetails?.hphone}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Enter valid phone number</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="validationCustom06" className='form_group'>
                                                        <Form.Label>County/State</Form.Label>
                                                        <Form.Control
                                                            // required
                                                            type="text"
                                                            placeholder=""
                                                            onChange={(e) => setCustomerDetails({ ...customerDetails, county: e.target.value })}
                                                            value={customerDetails.county}
                                                            ref={stateRef}
                                                        />
                                                        {customerDetails.country === "U.S.A." ? <p className='usrule'>US customers enter your 2-letter state code above</p> : null}
                                                        {/* <Form.Control.Feedback type="invalid">Enter county/state</Form.Control.Feedback> */}
                                                        {/* <Form.Control.Feedback className='usrule'></Form.Control.Feedback> */}
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="validationCustom07" className='form_group'>
                                                        <Form.Label>Postal/ Zip code <span className='darkred'>&#42;</span></Form.Label>
                                                        <Form.Control type="text" placeholder="" required
                                                            onChange={(e) => setCustomerDetails({ ...customerDetails, postcode: e.target.value })}
                                                            value={customerDetails.postcode} />
                                                        <Form.Control.Feedback type="invalid">
                                                            Provide a valid zip.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="9" controlId="validationCustom08" className='form_group'>
                                                        <Form.Label>Country <span className='darkred'>&#42;</span></Form.Label>
                                                        <Form.Select required
                                                            onChange={(e) => {
                                                                setCustomerDetails({ ...customerDetails, country: e.target.value })
                                                                if (e.target.value == "U.S.A.") {
                                                                    stateRef.current.focus()
                                                                }
                                                            }}
                                                            value={customerDetails.country}>
                                                            <option value=''></option>
                                                            {countryList.length > 0 && countryList.map((item, i) => {
                                                                return (<>
                                                                    <option value={item.printable_name} key={i}>{item.printable_name}</option>
                                                                </>)
                                                            })}
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            Select country
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="9" controlId="validationCustom09" className='form_group'>
                                                        <Form.Label>Payment method <span className='darkred'>&#42;</span></Form.Label>
                                                        <Form.Select required
                                                            onChange={(e) => setCustomerDetails({ ...customerDetails, payment: e.target.value })}
                                                            value={customerDetails.payment}
                                                        >
                                                            <option value=''></option>
                                                            <option value="Credit or Debit Card">Credit/Debit card</option>
                                                            <option value="Bank transfer">Bank transfer (sterling payments ONLY)</option>
                                                            <option value="Cheque">Sterling cheque</option>
                                                            <option value="Cash">Cash</option>
                                                            <option value="Other">Other (Please use 'Notes' below to specify)</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            Select payment method
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="12" controlId="validationCustom10" className='form_group'>
                                                        <Form.Label>Notes</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            style={{ height: '100px' }}
                                                            onChange={(e) => setCustomerDetails({ ...customerDetails, message: e.target.value })}
                                                            value={customerDetails.message}
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <Form.Group className='form_group d-flex align-items-center mb-1'>
                                                    <Form.Check
                                                        className='form_check'
                                                        onChange={(e) => setCustomerDetails({ ...customerDetails, promotional_emails: e.target.checked })}
                                                        checked={customerDetails.promotional_emails}
                                                    />
                                                    <Form.Label className='ms-2'>Receive promotional emails (not more than one per month).</Form.Label>
                                                </Form.Group>
                                                <Form.Group className='form_group d-flex align-items-center'>
                                                    <Form.Check
                                                        className='form_check'
                                                        required
                                                        feedback="Check the terms and condition checkbox"
                                                        feedbackType="invalid"
                                                    />
                                                    <Form.Label className='ms-2'>I have read and accepted the <a href={RouteStrings.contactus} target='_blank' className='f-14'>terms and conditions</a></Form.Label>
                                                </Form.Group>
                                                <div className="text-end mt-4">
                                                    <Button type="submit" className='cart_button' disabled={inactiveMsg ? true : false}>Continue</Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="my-5 pt-5">
                                <h2>Collectable items</h2>
                                <div className='row mt-4'>
                                    {collectableItems.length > 0 && collectableItems.map((item, i) => {
                                        return (
                                            <div className="col-md-4 col-xl-3 my-4" key={i}>
                                                <CartCard cartImg={(item.image && item.image.length > 0) ? item.image[0] : Images.emptyImage} cartbookAuthor={item.author} cartPrice={item.price}
                                                    cartText1={item.title} cartText2={item.description} onClick={() => handleProductDetail(item)} cartbookAuthor2={item.author2}
                                                    onCartClick={() => handleCartClick(item)} pubDate={item.pubdate} publisher={item.publisher} />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div> :
                        <div className='text-center mt-4'>
                            <img src={Images.emptyCartIcon} alt="icon" className='img-fluid emptycart_img' />
                            <p className='empty_cart'>Your shopping basket is empty.</p>
                        </div>}

                </div>
            </div>
        </div >
    )
}
