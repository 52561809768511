import axios from "axios";
import { ENVDATA } from "../conflict/Conflict";

export const baseUrl = ENVDATA.baseUrl;
export const sagePayUrl = ENVDATA.sagePayUrl;
export const protectedKey = ENVDATA.protectedKey;
export const siteUrl = ENVDATA.siteUrl;

export const configUrl = {
    getAllCateloguesRandom: "/view/allCategoryData/",
    getCatalogues: "/view/categories/",
    getlatestNote: "/cms/latestnote/",
    getSubCategories: "/view/subcategories/",
    getParticularCategoryData: "/view/category/",
    getProductDetailById: "/view/product_detail/",
    getContactInfo: "/cms/getcontactpage_content/",
    getBasketData: "/view/viewbasket/",
    getSubcategoryData: "/view/search-by-subcat/",
    getSearchDetails: "/view/search-keyword/",
    getDropdownOptions: "/view/getsubcat_dropdownlist/",
    getCountries: "/view/countries/",
    getCustomerDetails: "/view/checkuser/",
    registerCustomer: "/view/orderplacing/",
    getOtp: "/view/verifyemail/",
    verifyOtp: "/view/verifyotp/",
    getAboutPageContent: '/cms/getaboutpage_content/',
    getTrackorderList: "/view/getOrderdetailbyEmail/",
    getShippingAdrees: "/view/orderSummary/",
    getPackageData: "/view/getOrderdetailbyPackageId/",
    cancelorder: "/view/cancelorder/",
    cancelpackageorder: "/view/cancel_packedorder/",
    getHolidayNote: "/view/getholiday_note/",
    checkoutToPayment: "/view/sagepay/",
    verifyCustomer: "/view/verifyCustomerById/",
    getPackageCategories: "/view/postagecategory/",

    // paymentupdate endpoints
    updateInitiateTransDetails: "/view/insertTranscation/",
    updatetransDetails: "/view/updateTranscation/",
    updateTransStatus: "/view/paymentresult/",
}