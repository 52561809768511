import React from 'react'
import './toastbar.scss'
import { Toast } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { setToast } from '../../store/ui.reducer'

export const ToastBar = (props) => {
    const { msg, show, status } = props
    const dispatch = useDispatch()
    return (
        <Toast onClose={() => dispatch(setToast(false))} show={show} delay={3000} autohide className={`${status ? "addCart" : "response"}`}>
            <Toast.Header>
                <p className='mb-0 text-white px-2'>{msg}</p>
            </Toast.Header>
        </Toast>
    )
}
