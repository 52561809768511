import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { configUrl, protectedKey, sagePayUrl, siteUrl } from '../../apis/api.config';
import { ApiResponseHook } from '../../apis/Services';
import { Images } from '../../utils/Images';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RouteStrings } from '../../utils/RouteStrings';
import CryptoJS from 'crypto-js';
import { setPaymentItem, setOrderDetailStatus, setLoader, setActiveCatalogueType, setProductDetail } from '../../store/ui.reducer';
import { Categorybar } from '../../components/CategoryBar/Categorybar';

export const OrderSummary = ({ categories }) => {
    const location = useLocation();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { getRequest } = ApiResponseHook()
    const { orderDetailStatus } = useSelector((state) => state.localStorage)

    const [orderDetail, setOrderDetail] = useState({})
    const [packageDetail, setPackageDetail] = useState([])
    const [cancelOrder, setCancelOrder] = useState(false)
    const [cancelModal, setCancelModal] = useState(false)
    const [verifyOrder, setVerifyOrder] = useState(false)
    const [encryptedObject, setEncryptedObject] = useState({})
    const [encryptValue, setEncryptedValue] = useState("")
    const [checkoutpageData, setCheckoutpageData] = useState("")


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        // Access query parameters
        let orderData = ""
        const orderdetail = queryParams.get('od');
        const orderCancel = queryParams.get('co');

        if (orderdetail) {
            orderData = orderdetail
            dispatch(setPaymentItem(orderdetail))
        } else if (orderCancel) {
            orderData = orderCancel
            dispatch(setOrderDetailStatus(true))
            setCancelOrder(true)
        }

        try {
            const decodedData = JSON.parse(atob(orderData));
            if (decodedData.orderno) {
                handleTrackOrderData(decodedData.orderno, decodedData.email)
            } else if (decodedData.packageno) {
                handlePackageData(decodedData.packageno, decodedData.email)
            }
        } catch (error) {
            console.error('Error decoding Base64:');
        }
    }, [])

    // get the details of order
    const handleTrackOrderData = async (orderNo, orderEmail) => {
        let response = await getRequest(`${configUrl.getShippingAdrees}${orderNo}/${orderEmail}/`)

        if (response?.data?.status === 200 && (Object.keys(response?.data).length > 0)) {
            let responsedata = response?.data ? response?.data : {}
            setOrderDetail(responsedata)
            let _encryptObject = {
                amount: responsedata.grandtotal,
                customerName: (`${responsedata.custmerdetail.name} ${responsedata.custmerdetail.forename}`).trim(),
                customerEMail: responsedata.custmerdetail.email,
                billingSurname: (`${responsedata.custmerdetail.name} ${responsedata.custmerdetail.forename}`).trim().substring(0, 19),
                billingFirstnames: "Mr/Ms",
                billingAddress1: (responsedata.custmerdetail.address1).trim().substring(0, 48),
                billingCity: responsedata.custmerdetail.county ? (responsedata.custmerdetail.county).trim().substring(0, 48) : (responsedata.countrydata[0].printable_name).trim().substring(0, 48),
                billingState: responsedata.custmerdetail.county ? ((responsedata.custmerdetail.county).slice(0, 2)).toUpperCase() : "AL",
                billingPostcode: (responsedata.custmerdetail.postcode).trim().substring(0, 10),
                billingCountry: responsedata.countrydata[0].iso,
            }
            setEncryptedObject(_encryptObject)
        } else {
            setOrderDetail({})
        }

    }

    // get the details of packed orders
    const handlePackageData = async (packageno, orderEmail) => {
        let response = await getRequest(`${configUrl.getPackageData}${orderEmail}/${packageno}/`)
        let responsedata = response?.data?.orderDetails ? response?.data?.orderDetails : []
        if (responsedata.length > 0 && responsedata[0].orderDetail.status === "1") {
            let _encryptObject = {
                amount: Number(responsedata.reduce((sum, order) => sum + order.orderTotal, 0)) + Number(responsedata[0].packageDetail[0].postage) + Number(responsedata[0].packageDetail[0].pricechange),
                customerName: (`${responsedata[0].name} ${responsedata[0].forename}`).trim(),
                customerEMail: orderEmail,
                billingFirstnames: "Mr/Ms",
                billingSurname: (`${responsedata[0].name} ${responsedata[0].forename}`).trim().substring(0, 19),
                billingAddress1: (responsedata[0].orderDetail.address1).trim().substring(0, 48),
                billingCity: responsedata[0].orderDetail.county ? (responsedata[0].orderDetail.county).trim().substring(0, 48) : (responsedata[0].countrydata[0].printable_name).trim().substring(0, 48),
                billingState: responsedata[0].orderDetail.county ? ((responsedata[0].orderDetail.county).slice(0, 2)).toUpperCase() : "AL",
                billingPostcode: (responsedata[0].orderDetail.postcode).trim().substring(0, 10),
                billingCountry: responsedata[0].countrydata[0].iso,
            }
            setEncryptedObject(_encryptObject)
        }
        setPackageDetail(responsedata)
    }

    // cancel order
    const handleCancelOrder = async (data) => {
        if (Array.isArray(data)) {
            let response = await getRequest(`${configUrl.cancelpackageorder}${data[0].orderDetail.package_id}/`)
            if (response.status === 200) {
                setVerifyOrder(true)
            }
        }
        else {
            let response = await getRequest(`${configUrl.cancelorder}${data.ordersummary[0].orderdetail.orderno}/`)
            if (response.status === 200) {
                setVerifyOrder(true)
            }
        }
    }


    // component for the items to view
    const ViewComponet = (passingdata) => {
        const { image, author, price, title, description, publisher, pubdate } = passingdata
        return <div className="card checkout_card">
            <div className="row">
                <div className="col-md-5 col-lg-3 mb-3 mb-md-0">
                    <div>
                        <div className='text-center'>
                            <img src={image} className="img-fluid cart_img" alt={`${author} ${title}`} />
                        </div>
                    </div>
                </div>
                <div className="col-md-7 col-lg-9">
                    <div className="">
                        <div className="d-flex justify-content-between align-items-end mb-3">
                            <h5 className="">{author}</h5>
                            <h5 className='price_width'>&pound; {price}</h5>
                        </div>
                        <p className='mb-2'><strong>{publisher} {pubdate && ` (${pubdate})`}</strong></p>
                        <p className='mb-2'>{title}</p>
                        <p>{description}</p>
                    </div>
                </div>
            </div>
        </div>
    }

    // form method payment gateway starts
    const generateNonRepeatingRandom = (min, max) => {
        const numbers = Array.from({ length: max - min + 1 }, (_, index) => index + min);
        let currentIndex = numbers.length;

        return function getRandomNonRepeatingNumber() {
            if (currentIndex <= 0) {
                currentIndex = numbers.length;
            }

            const randomIndex = Math.floor(Math.random() * currentIndex);
            const randomValue = numbers[randomIndex];

            // Remove the selected number to avoid repetition
            numbers[randomIndex] = numbers[currentIndex - 1];
            currentIndex--;

            return randomValue;
        };
    }
    const generateUniqueRandom = generateNonRepeatingRandom(0, 32000);


    const formInputString = (data) => {
        let vendorname = 'pennymeadbooks';
        let _vendorTxCode = `${vendorname}${generateUniqueRandom()}${generateUniqueRandom()}`
        let _amount = data.amount
        let _customerName = data.customerName
        let _customerEMail = data.customerEMail
        let _billingSurname = data.billingSurname
        let _billingFirstnames = data.billingFirstnames
        let _billingAddress1 = data.billingAddress1
        let _billingCity = data.billingCity
        let _billingState = data.billingState
        let _billingPostcode = data.billingPostcode
        let _billingCountry = data.billingCountry
        if (_billingCountry == "US") {
            let encryptingValue = `VendorTxCode=${_vendorTxCode}&Amount=${_amount}&Currency=GBP&Description=Order placed with pennymead.com&SuccessURL=${siteUrl}/payment?status=1&FailureURL=${siteUrl}/payment?status=0&CustomerName=${_customerName}&CustomerEMail=${_customerEMail}&BillingSurname=${_billingSurname}&BillingFirstnames=${_billingFirstnames}&BillingAddress1=${_billingAddress1}&BillingCity=${_billingCity}&BillingPostcode=${_billingPostcode}&BillingCountry=${_billingCountry}&BillingState=${_billingState}&DeliverySurname=${_billingSurname}&DeliveryFirstnames=${_billingFirstnames}&DeliveryAddress1=${_billingAddress1}&DeliveryCity=${_billingCity}&DeliveryPostcode=${_billingPostcode}&DeliveryCountry=${_billingCountry}&DeliveryState=${_billingState}`
            return encryptingValue
        } else {
            let encryptingValue = `VendorTxCode=${_vendorTxCode}&Amount=${_amount}&Currency=GBP&Description=Order placed with pennymead.com&SuccessURL=${siteUrl}/payment?status=1&FailureURL=${siteUrl}/payment?status=0&CustomerName=${_customerName}&CustomerEMail=${_customerEMail}&BillingSurname=${_billingSurname}&BillingFirstnames=${_billingFirstnames}&BillingAddress1=${_billingAddress1}&BillingCity=${_billingCity}&BillingPostcode=${_billingPostcode}&BillingCountry=${_billingCountry}&DeliverySurname=${_billingSurname}&DeliveryFirstnames=${_billingFirstnames}&DeliveryAddress1=${_billingAddress1}&DeliveryCity=${_billingCity}&DeliveryPostcode=${_billingPostcode}&DeliveryCountry=${_billingCountry}`
            return encryptingValue
        }
    }
    const encryptStringToHex = (inputString, password) => {
        // Ensure the password is 16 bytes (128 bits) long (AES block size)
        password = CryptoJS.enc.Utf8.parse(password.padEnd(16, '\0'));

        // Encrypt the input string using AES in CBC mode with PKCS#5 padding
        const encrypted = CryptoJS.AES.encrypt(inputString, password, {
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
            iv: password,
        });

        // Convert the encrypted result to hex (uppercase)
        const hexEncryptedString = encrypted.ciphertext.toString(CryptoJS.enc.Hex).toUpperCase();
        return hexEncryptedString;
    };
    useEffect(() => {
        if (Object.keys(encryptedObject).length > 0) {

            const plaintext = formInputString(encryptedObject);
            const password = protectedKey;
            setEncryptedValue(encryptStringToHex(plaintext, password))
        }
    }, [encryptedObject])

    useEffect(() => {
        if (encryptValue) {
            checkOutPayment()
        }
    }, [encryptValue])

    const checkOutPayment = async () => {
        let _sagePayUrl = sagePayUrl;
        let encodedURL = btoa(JSON.stringify(_sagePayUrl));
        let response = await getRequest(`${configUrl.checkoutToPayment}${encryptValue}/${encodedURL}/`)
        setCheckoutpageData(response.data ? response.data : "")
    }
    // form method payment gateway ends

    const getDate = (date) => {
        let _date = date.split(" ")[0]
        _date = new Date(_date).toLocaleDateString()
        return _date
    }

    return (
        <>
            <div className="ordersummary">
                <div className='container'>
                    <Categorybar categories={categories} subCategory={[]}
                        subcategoryVisible={false} />
                    <div className='my-5'>
                        {(Object.keys(orderDetail).length > 0 && packageDetail.length > 0) ?
                            <h2> {orderDetailStatus ? "Order Details" : "Order Summary"}</h2> : null}

                        <div className="mt-4">
                            {Object.keys(orderDetail).length > 0 ?
                                <div className="row">
                                    <div className="col-md-7 col-lg-7 order-1 order-md-0">
                                        <div className="order_div">
                                            <h5 className='d-md-none text-decoration-underline mb-3'>Order items</h5>
                                            {orderDetail?.ordersummary && orderDetail.ordersummary[0].itemsdetail.map((subItem, j) => {
                                                return (<>
                                                    <ViewComponet image={(subItem.image && subItem.image.length > 0) ? subItem.image[0] : Images.emptyImage} key={j}
                                                        author={subItem.author} price={subItem.orderprice} title={subItem.title} description={subItem.description}
                                                        publisher={subItem.publisher} pubdate={subItem.pubdate} />
                                                </>)
                                            })}
                                        </div>
                                    </div>
                                    <div className="col-md-5 col-lg-5 bg-cream order-0 order-md-1">
                                        <div className="order_div p-3">
                                            <div className="d-flex justify-content-between">
                                                <p className='mb-3'><strong>Order no :</strong> <strong>{orderDetail?.ordersummary[0]?.orderdetail?.orderno}</strong></p>
                                                <p className='text-end'>Date : {getDate(orderDetail?.ordersummary[0]?.orderdetail?.ordate)}</p>
                                            </div>
                                            {orderDetail?.ordersummary[0]?.orderdetail?.status === "0" ? <p className='mb-4'><strong> Order Status: Order Cancelled </strong></p> :
                                                <p className='mb-4'><strong>Payment Status :</strong>  <strong>{(orderDetail?.ordersummary[0]?.orderdetail?.invoiced === "0") ? "Waiting for Invoice" :
                                                    <>{((orderDetail?.ordersummary[0]?.orderdetail?.invoiced === "1") && (orderDetail?.ordersummary[0]?.orderdetail?.transtatus === "")) ? "Payment Pending" : "Completed/Paid"}</>}</strong></p>}
                                            <div className='px-2'>
                                                {orderDetail?.ordersummary[0]?.orderdetail?.status === "1" &&
                                                    <div>
                                                        {(((orderDetail.ordersummary[0].orderdetail.transtatus).toLowerCase() === "received") ||
                                                            ((orderDetail.ordersummary[0].orderdetail.transtatus).toLowerCase() === "success") || (orderDetail?.ordersummary[0]?.orderdetail?.transtatus === "1"))
                                                            && <div className='mb-4'>{orderDetail.ordersummary[0].orderdetail.order_status ? <>
                                                                <p className='mb-2 text-decoration-underline'><strong>Track Details</strong></p>
                                                                <p className='mb-2'><strong>Status : {orderDetail?.ordersummary[0]?.orderdetail?.order_status}</strong></p>
                                                                {orderDetail?.ordersummary[0]?.orderdetail?.order_note && <p><strong>Note : {orderDetail?.ordersummary[0]?.orderdetail?.order_note}</strong></p>}
                                                            </> : <>
                                                                <p className='mb-2'><strong>Order Status : Order Placed</strong></p>
                                                            </>}
                                                            </div>}
                                                    </div>}
                                                {(orderDetail?.ordersummary[0]?.orderdetail?.invoiced === "1" || orderDetail?.autoship == 1) ? <>
                                                    <p className='mb-2 d-flex justify-content-between'><strong>Total Items Price :</strong><strong> &pound; <span>{orderDetail?.ordertotal}</span></strong></p>
                                                    <p className='mb-2 d-flex justify-content-between'><strong>Postage & packing :</strong><strong> &pound; <span>{orderDetail?.postageandpacking}</span></strong></p>
                                                    {orderDetail.discount !== 0 &&
                                                        <p className='mb-2 d-flex justify-content-between'><strong>{orderDetail.discount < 1 ? "Discount" : "Price Adjustment"} :</strong><strong>  &pound; <span>{orderDetail.discount.toString().replace("-", "")}</span></strong></p>}
                                                    <div className='mb-2 d-flex justify-content-between border_top py-2'>
                                                        <div>
                                                            <h5 className='mb-0'><strong> Total :</strong></h5>
                                                        </div>
                                                        <h5 className='mb-0'><strong> &pound; <span>{orderDetail.grandtotal}</span></strong></h5>
                                                    </div>
                                                </> :
                                                    <p className='mb-2'><strong className='me-2'>Total Items Price :</strong> <strong>&pound; <span>{orderDetail.ordertotal}</span></strong></p>}
                                            </div>
                                            <div className="d-flex mt-4">
                                                <div className='me-2'><img src={Images.locationIcon} alt="icon" /></div>
                                                <div>
                                                    <p className="mb-2"> <strong>Delivery Address :</strong></p>
                                                    <p className='mb-1'><>{orderDetail?.ordersummary[0]?.orderdetail?.address1}</>,&nbsp;
                                                        <>{orderDetail?.ordersummary[0]?.orderdetail?.address2 && `${orderDetail?.ordersummary[0]?.orderdetail?.address2},`}</>&nbsp;
                                                        <>{orderDetail?.ordersummary[0]?.orderdetail?.county && <>{`${orderDetail?.ordersummary[0]?.orderdetail?.county},`}</>}</></p>
                                                    <p className='mb-3'><>{orderDetail?.ordersummary[0]?.orderdetail?.country}</>&nbsp;-&nbsp;
                                                        <>{orderDetail?.ordersummary[0]?.orderdetail?.postcode}</></p>
                                                    {orderDetail?.ordersummary[0]?.orderdetail?.hphone && <p>Phone Number : <>{orderDetail?.ordersummary[0]?.orderdetail?.hphone}</></p>}
                                                </div>
                                            </div>
                                            {orderDetail?.ordersummary[0]?.orderdetail?.status === "1" &&
                                                <>
                                                    {orderDetail.autoship == 0 && orderDetail?.ordersummary[0]?.orderdetail?.invoiced === "0" &&
                                                        <div className='mt-4'>
                                                            <p><strong>Your order has been placed. You will receive an e-mail shortly detailing how to pay for this order</strong></p>
                                                            <div className="">
                                                                <p className='mb-1'><strong>Please note:</strong></p>
                                                                <p> Most e-mail systems nowadays use junk mail filters to filter out the huge amounts of unsolicited email being sent every day. In some cases, these filters may mark a legitimate email as junk. If you have not received your invoice e-mail within 24 hours, please check your junk mail folder to make sure it hasn't been put there.
                                                                    If you've registered with the site you can also pay your invoice from your 'Track Order' page.
                                                                </p>
                                                            </div>
                                                        </div>}
                                                    {((orderDetail.ordersummary[0].orderdetail.transtatus === "") && orderDetail?.ordersummary[0]?.orderdetail?.invoiced === "1" && orderDetail?.ordersummary[0]?.orderdetail?.paymeth === "Bank transfer") ? <div className='mt-4'>
                                                        <p>Your order has been received and processed and is ready to pay for.</p>
                                                        <p className='mb-2'><strong>To pay by bank transfer IN STERLING ONLY</strong>, please use the
                                                            following details:</p>
                                                        <p className='mb-2'>Bank name - <strong>Santander</strong></p>
                                                        <p className='mb-2'>Account name - <strong>Pennymead Books</strong></p>
                                                        <p className='mb-2'>IBAN - <strong>GB47ABBY09015003566447</strong></p>
                                                        <p className='mb-2'>BIC - <strong>ABBYGB2LXXX</strong></p>
                                                        <p className='mb-2'>Sort code - <strong>09-01-50</strong></p>
                                                        <p className='mb-2'>Account number - <strong>03566447</strong></p>
                                                        <p>Your order number: <strong>{orderDetail?.ordersummary[0]?.orderdetail?.orderno}</strong></p>
                                                    </div> : <>{((orderDetail.ordersummary[0].orderdetail.transtatus === "") && (orderDetail?.ordersummary[0]?.orderdetail?.invoiced === "1") && (orderDetail?.ordersummary[0]?.orderdetail?.paymeth === "Cheque" || orderDetail?.ordersummary[0]?.orderdetail?.paymeth === "Cash")) ? <div className='mt-4'>
                                                        <p>Your order has been received and processed and is ready to pay for. </p>
                                                        <p>Please make <strong>UK pound cheque payments</strong> payable to Pennymead Auctions.</p>
                                                        <p className='mb-2'><strong>Cheque and cash payments</strong> should be posted to:</p>
                                                        <p className='mb-2'><strong>1 Brewerton Street, Knaresborough</strong></p>
                                                        <p className='mb-3'><strong>HG5 8AZ, UNITED KINGDOM</strong></p>
                                                        <p className='mb-2'>Cash sent through the post is at your own risk</p>
                                                        <p>Your order number: <strong>{orderDetail?.ordersummary[0]?.orderdetail?.orderno}</strong></p>
                                                    </div> : null}
                                                    </>}
                                                    {cancelOrder ? <>{((orderDetail?.ordersummary[0]?.orderdetail?.transtatus === "1") || ((orderDetail.ordersummary[0].orderdetail.transtatus).toLowerCase() === "received") || ((orderDetail.ordersummary[0].orderdetail.transtatus).toLowerCase() === "success")) ? null :
                                                        <div className='mt-5 mb-4 text-end'>
                                                            <Button className='cart_button' onClick={() => {
                                                                setCancelModal(true)
                                                            }}>Cancel Order</Button>
                                                        </div>
                                                    }</>
                                                        : <>

                                                            {((orderDetail.ordersummary[0].orderdetail.invoiced === "1") && ((orderDetail?.ordersummary[0]?.orderdetail?.transtatus === "") || (orderDetail?.ordersummary[0]?.orderdetail?.transtatus === "0"))) ?
                                                                <div className="mt-4 mb-5 mt-md-5 mb-md-4 text-end">
                                                                    {(orderDetail?.ordersummary[0]?.orderdetail?.invoiced === "1" && (orderDetail?.ordersummary[0]?.orderdetail?.paymeth === "Bank transfer" || orderDetail?.ordersummary[0]?.orderdetail?.paymeth === "Cheque" || orderDetail?.ordersummary[0]?.orderdetail?.paymeth === "Cash")) ? <h6>Or To Pay by Credit or Debit card</h6> : null}
                                                                    <div className='mb-1' onClick={() => { dispatch(setLoader(true)) }} dangerouslySetInnerHTML={{ __html: checkoutpageData }} />
                                                                </div> : null}
                                                        </>}
                                                </>}
                                        </div>
                                    </div >
                                </div > :
                                <>{packageDetail.length > 0 ? <>
                                    <div className="row">
                                        <div className="col-md-7 order-1 order-md-0">

                                            <div className="order_div">
                                                <h5 className='d-md-none text-decoration-underline mb-3'>Package items</h5>
                                                {packageDetail.map((item, i) => {
                                                    return (
                                                        <div className='mb-4 border-packagebottom'>
                                                            <div className="d-md-flex justify-content-between">
                                                                <h6>Order Number: <strong>{item.orderno}</strong></h6>
                                                                <p className="mb-0 mt-2 mt-md-0 text-end d-none d-md-block">Order Date: <span>{getDate(item.orderDetail.ordate)}</span></p>
                                                            </div>
                                                            <>{item.orderSummary.map((subitem, j) => {
                                                                return (<>   <ViewComponet image={(subitem.image && subitem.image.length > 0) ? subitem.image[0] : Images.emptyImage} key={j}
                                                                    author={subitem.author} price={subitem.orderprice} title={subitem.title} description={subitem.description}
                                                                    publisher={subitem.publisher} pubdate={subitem.pubdate} />
                                                                </>)
                                                            })}</>
                                                        </div>)
                                                })}
                                            </div>
                                        </div>
                                        <div className="col-md-5 bg-cream order-0 order-md-1">
                                            <div className="order_div p-3">
                                                <div className="d-lg-flex justify-content-between mb-3">
                                                    <p className='mb-2'><strong>Package no :</strong> <strong>{packageDetail[0]?.packageDetail[0]?.package_id}</strong></p>
                                                </div>
                                                {packageDetail[0]?.orderDetail?.status === "0" ? <p className='mb-4'><strong> Order Status: Order Cancelled </strong></p> :
                                                    <p className='mb-4'><strong>Payment Status :{<>{(packageDetail[0]?.orderDetail?.invoiced === "0") ? "Waiting for Invoice" :
                                                        <>{((packageDetail[0].orderDetail?.invoiced === "1") && (packageDetail[0].orderDetail?.transtatus === "")) ? "Payment Pending" : "Completed/Paid"}</>}</>} </strong></p>}
                                                <div className='px-2'>
                                                    <div>
                                                        {(((packageDetail[0].orderDetail?.transtatus).toLowerCase() === "received") ||
                                                            ((packageDetail[0].orderDetail?.transtatus).toLowerCase() === "success") || (packageDetail[0].orderDetail?.transtatus === "1"))
                                                            && <div className='mb-4'>{packageDetail[0]?.packageDetail[0]?.package_status ? <>
                                                                <p className='mb-2 text-decoration-underline'><strong>Track Details</strong></p>
                                                                <p className='mb-2'><strong>Status : {packageDetail[0]?.packageDetail[0]?.package_status}</strong></p>
                                                                {packageDetail[0]?.packageDetail[0]?.package_note && <p><strong>Note : {packageDetail[0]?.packageDetail[0]?.package_note}</strong></p>}
                                                            </> : <>
                                                                <p className='mb-2'><strong>Order Status : Order Placed</strong></p>
                                                            </>}
                                                            </div>}
                                                    </div>
                                                    {(packageDetail[0].orderDetail?.invoiced === "1") ? <>
                                                        <p className='mb-2 d-flex justify-content-between'><strong>Total Items Price :</strong><strong> &pound; <span>{packageDetail.reduce((sum, order) => sum + order.orderTotal, 0)}</span></strong></p>
                                                        <p className='mb-2 d-flex justify-content-between'><strong>Postage & packing :</strong><strong> &pound; <span>{packageDetail[0]?.packageDetail[0]?.postage}</span></strong></p>
                                                        {packageDetail[0]?.packageDetail[0]?.pricechange !== "0" &&
                                                            <p className='mb-2 d-flex justify-content-between'><strong>{packageDetail[0]?.packageDetail[0]?.pricechange < 1 ? "Discount" : "Price Adjustment"} :</strong><strong>  &pound; <span>{packageDetail[0]?.packageDetail[0]?.pricechange.toString().replace("-", "")}</span></strong></p>}
                                                        <div className='mb-2 d-flex justify-content-between border_top py-2'>
                                                            <div>
                                                                <h5 className='mb-0'><strong> Total :</strong></h5>
                                                            </div>
                                                            <h5 className='mb-0'><strong> &pound; <span>{Number(packageDetail.reduce((sum, order) => sum + order.orderTotal, 0)) + Number(packageDetail[0]?.packageDetail[0]?.postage) + Number(packageDetail[0]?.packageDetail[0]?.pricechange)}</span></strong></h5>
                                                        </div></> : <>
                                                        <p className='mb-2'><strong className='me-2'>Total Items Price :</strong><strong> &pound; <span>{packageDetail.reduce((sum, order) => sum + order.orderTotal, 0)}</span></strong></p>
                                                    </>}
                                                </div>
                                                <div className="d-flex mt-4">
                                                    <div className='me-2'><img src={Images.locationIcon} alt="icon" /></div>
                                                    <div>
                                                        <p className="mb-2"> <strong>Delivery Address :</strong></p>
                                                        <p className='mb-1'><>{packageDetail[0]?.orderDetail?.address1}</>,&nbsp;
                                                            <>{packageDetail[0]?.orderDetail?.address2 && `${packageDetail[0]?.orderDetail?.address2},`}</>&nbsp;
                                                            <>{packageDetail[0]?.orderDetail?.county && <>{`${packageDetail[0]?.orderDetail?.county},`}</>}</></p>
                                                        <p className='mb-3'><>{packageDetail[0]?.orderDetail?.country}</>&nbsp;-&nbsp;
                                                            <>{packageDetail[0]?.orderDetail?.postcode}</></p>
                                                        {packageDetail[0]?.orderDetail?.hphone && <p>Phone Number : <>{packageDetail[0]?.orderDetail?.hphone}</></p>}
                                                    </div>
                                                </div>

                                                {packageDetail[0]?.orderDetail?.status === "1" && packageDetail[0]?.orderDetail?.invoiced === "0" && <div className='mt-4'>
                                                    <p><strong>Your Order Placed, You will receive an e-mail shortly detailing how to pay
                                                        for this order.</strong></p>

                                                    <div className="">
                                                        <p className='mb-1'><strong>Please note:</strong></p>
                                                        <p> Most e-mail systems nowadays use junk mail filters to filter out the huge amounts of unsolicited email being sent every day.
                                                            In some cases, these filters may mark a legitimate email as junk. If you have not received your invoice e-mail within 24 hours,
                                                            please check your junk mail folder to make sure it hasn't been put there.
                                                            If you've registered with the site you can also pay your invoice from your 'My account' page.
                                                        </p>
                                                    </div>
                                                </div>}
                                                {packageDetail[0]?.orderDetail?.status === "1" && <>
                                                    {((packageDetail[0]?.orderDetail?.transtatus === "") && (packageDetail[0]?.orderDetail?.invoiced === "1" && packageDetail[0]?.orderDetail?.paymeth === "Bank transfer")) ? <div className='mt-4'>
                                                        <p>Your order has been received and processed and is ready to pay for.</p>
                                                        <p className='mb-2'><strong>To pay by bank transfer IN STERLING ONLY</strong>, please use the
                                                            following details:</p>
                                                        <p className='mb-2'>Bank name - <strong>Santander</strong></p>
                                                        <p className='mb-2'>Account name - <strong>Pennymead Books</strong></p>
                                                        <p className='mb-2'>IBAN - <strong>GB47ABBY09015003566447</strong></p>
                                                        <p className='mb-2'>BIC - <strong>ABBYGB2LXXX</strong></p>
                                                        <p className='mb-2'>Sort code - <strong>09-01-50</strong></p>
                                                        <p className='mb-2'>Account number - <strong>03566447</strong></p>
                                                        <p>Your order number: <strong>{packageDetail[0]?.orderDetail?.orderno}</strong></p>
                                                    </div> : <>{((packageDetail[0]?.orderDetail?.transtatus === "") && (packageDetail[0]?.orderDetail?.invoiced === "1") && (packageDetail[0]?.orderDetail?.paymeth === "Cheque" || packageDetail[0]?.orderDetail?.paymeth === "Cash")) ? <div className='mt-4'>
                                                        <p>Your order has been received and processed and is ready to pay for. </p>
                                                        <p>Please make <strong>UK pound cheque payments</strong> payable to Pennymead Auctions.</p>
                                                        <p className='mb-2'><strong>Cheque and cash payments</strong> should be posted to:</p>
                                                        <p className='mb-2'><strong>1 Brewerton Street, Knaresborough</strong></p>
                                                        <p className='mb-3'><strong>HG5 8AZ, UNITED KINGDOM</strong></p>
                                                        <p className='mb-2'>Cash sent through the post is at your own risk</p>
                                                        <p>Your order number: <strong>{packageDetail[0]?.orderDetail?.orderno}</strong></p>
                                                    </div> : null}
                                                    </>}</>}
                                                {cancelOrder && packageDetail[0]?.orderDetail?.status === "1" ? <>{((packageDetail[0]?.orderDetail?.transtatus === "1") || ((packageDetail[0]?.orderDetail?.transtatus).toLowerCase() === "received") || ((packageDetail[0]?.orderDetail?.transtatus).toLowerCase() === "success")) ? null :
                                                    <div className='mt-5 mb-4 text-end'>
                                                        <Button className='cart_button' onClick={() => {
                                                            setCancelModal(true)
                                                        }}>Cancel Total package Orders</Button>
                                                    </div>
                                                }
                                                </> :
                                                    <>
                                                        {((packageDetail[0]?.orderDetail?.status === "1") && (packageDetail[0]?.orderDetail?.invoiced === "1") && ((packageDetail[0]?.orderDetail?.transtatus === "") || (packageDetail[0]?.orderDetail?.transtatus === "0"))) ?
                                                            <div className="mt-4 mb-5 mt-md-5 mb-md-4 text-end">
                                                                {(packageDetail[0]?.orderDetail?.invoiced === "1" && (packageDetail[0]?.orderDetail?.paymeth === "Bank transfer" || packageDetail[0]?.orderDetail?.paymeth === "Cheque" || packageDetail[0]?.orderDetail?.paymeth === "Cash")) ? <h6>Or To Pay by Credit or Debit card</h6> : null}

                                                                <div className='mb-1' onClick={() => { dispatch(setLoader(true)) }} dangerouslySetInnerHTML={{ __html: checkoutpageData }} />
                                                            </div> : null
                                                        }
                                                    </>}
                                            </div>
                                        </div >
                                    </div >
                                </> : <h4 className='text-center'>No Data Found</h4>
                                }</>}
                        </div >
                    </div >
                </div >
            </div>

            {/* cancel order module */}
            <Modal
                show={cancelModal}
                onHide={() => setCancelModal(false)}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body>
                    {verifyOrder ?
                        <h5>Your {packageDetail.length > 0 ? "Package" : 'order'} <strong>#{packageDetail.length > 0 ? packageDetail[0].orderDetail.package_id : orderDetail.ordersummary && orderDetail.ordersummary[0].orderdetail.orderno}</strong> has been successfully cancelled, as per your request.</h5>
                        : <h5> Are you sure you want to cancel the order <strong>#{packageDetail.length > 0 ? packageDetail[0].orderDetail.package_id : orderDetail.ordersummary && orderDetail.ordersummary[0].orderdetail.orderno}</strong></h5>}
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-end gap-3">
                        <Button className="cart_button" onClick={() => {
                            setCancelModal(false)
                            if (verifyOrder) {
                                navigate(RouteStrings.trackorder)
                            }
                        }}>
                            Close
                        </Button>
                        {verifyOrder ? null :
                            <Button className="cart_button" onClick={() => {
                                handleCancelOrder(packageDetail.length > 0 ? packageDetail : orderDetail)
                            }}>
                                Cancel Order
                            </Button>}
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}
