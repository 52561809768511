import React, { useState } from 'react'
import './categorybar.scss'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { resetSearch, setActiveCatalogueType, setDropdownStatus, setSearchDetails, setSearchStatus, setSubCategoryData } from '../../store/ui.reducer'
import { RouteStrings } from '../../utils/RouteStrings'
import { Dropdown } from 'react-bootstrap'
import { SearchBar } from '../Searchbar/SearchBar'

export const Categorybar = (props) => {
    const { subcategoryVisible, categories, activeCatalogueType, subCategory } = props
    const { basketOrder, isDropdownStatus, subCategoryData,
        isSearch } = useSelector((state) => state.localStorage)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // serach dropdown data
    const handleDropdownData = (data) => {
        dispatch(setDropdownStatus(true))
        dispatch(setSearchStatus(false))
        navigate(`${RouteStrings.cataloguelist}?ct=${activeCatalogueType}&sub=${data.reference}`, { state: data })
    }

    const _handleDropdownData = () => {
        dispatch(setDropdownStatus(true))
        dispatch(setSearchStatus(false))
    }
    // redirect to cataloguelist page with clicked catalogue type
    const handleCatalogueList = (category) => {
        dispatch(setDropdownStatus(false))
        dispatch(setSearchStatus(false))
        // navigate(`${RouteStrings.cataloguelist}?ct=${category}`)
    }
    return (
        <div className='categorybar'>
            <h4 className='mb-3 mb-md-4'>Categories</h4>
            <div className="row g-0 d-none d-md-flex">
                {categories.length > 0 && categories.map((item, i) => {
                    return (
                        <div key={i} className={`col catalogue_nav ${item.category === activeCatalogueType ? "isActive" : ""}`}>
                            {/* <p className={`text-center mb-0 cursor-pointer`} onClick={() => handleCatalogueList(item.category)}>{item.name}</p> */}
                            <p className={`text-center mb-0`} onClick={() => handleCatalogueList(item.category)}>
                                <Link to={`${RouteStrings.cataloguelist}?ct=${item.category}`} className='cart_book'>{item.name}</Link></p>

                        </div>
                    )
                })}
            </div>
            <div className="d-flex d-md-none filter_drop mb-3">
                <select name="filter_options" id="filter_options" className='cataloguenav'
                    value={activeCatalogueType ? activeCatalogueType : "0"} onChange={(e) => handleCatalogueList(e.target.value)}>
                    {((isSearch && !activeCatalogueType) || !subcategoryVisible) && <option value="0" >Select Category</option>}

                    {categories.length > 0 && categories.map((item, index) => (
                        <option value={item.category} key={index}>{item.name}</option>
                    ))}
                </select>
                <div className="drp_icon"></div>
            </div>
            <div className='sortsubcategories mt-2 mt-md-4'>
                {subCategory.length > 0 && <h5 className='subcategoryhead d-none d-md-block'>Search by</h5>}
                <div className="row mt-md-4">
                    <div className="col-xl-6">
                        {activeCatalogueType &&
                            <>
                                {subcategoryVisible && <div className="drpdown_div">
                                    <div className="d-flex gap-2" style={{ minHeight: activeCatalogueType ? "52px" : "auto" }}>
                                        {subCategory.length > 0 && subCategory.map((item, i) => {
                                            return (
                                                <>
                                                    <div className="d-flex d-md-none filter_drop mb-2" key={i}>
                                                        <select name="filter_options" className='cataloguenav w-175' value={item.name}
                                                            onChange={(e) => {
                                                                let xyz = (item.dropdownlist.length > 0 ? item.dropdownlist : []).filter(item => item.reference == Number(e.target.value))
                                                                handleDropdownData(xyz[0])
                                                            }} key={i}>
                                                            <option value={item.name} selected className='d-none'>{item.name}</option>
                                                            {item.dropdownlist.length > 0 && item.dropdownlist.map((item1, j) => {
                                                                return (
                                                                    <option value={item1.reference} key={j}>{item1.name}</option>

                                                                )
                                                            })}
                                                        </select>
                                                        <div className="drp_icon"></div>
                                                    </div>
                                                    <div className='d-none d-md-block'>
                                                        <Dropdown key={i}>
                                                            <Dropdown.Toggle className="subcategorydrpdwn" id='dropdown-basic'>
                                                                {item.name}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu className='category_dropdowns py-2'>
                                                                <div className="row">
                                                                    {item.dropdownlist.length > 0 && item.dropdownlist.map((item2, j) => {
                                                                        return (<div className='col-6 col-md-4 col-md-3 my-auto' key={j}>
                                                                            <Dropdown.Item onClick={() => handleDropdownData(item2)}>{item2.name}</Dropdown.Item>
                                                                            {/* <div className='drp_nav_link_div'>
                                                                                <Link to={`${RouteStrings.cataloguelist}?ct=${activeCatalogueType}&sub=${item2.reference}`} onClick={() => { _handleDropdownData() }} className='drp_nav_link'>{item2.name}</Link>
                                                                            </div> */}
                                                                        </div>)
                                                                    })}
                                                                </div>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </>

                                            )
                                        })}
                                    </div>
                                </div>}
                            </>}
                    </div>
                    {subcategoryVisible && <div className="col-xl-6 mt-4 mt-xl-0">
                        <SearchBar category={activeCatalogueType} isVisible={Number(activeCatalogueType ? activeCatalogueType : 0) > 0 ? true : false} />
                    </div>}
                </div>
            </div>
        </div>
    )
}
