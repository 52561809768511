import logo from "../assets/logo.svg"
import logowhite from "../assets/logo_white.svg"
import bgImage from "../assets/backgorundimage.png"
import cartImage from "../assets/shoppingcart.svg"
import fbicon from "../assets/fbicon.svg"
import linkedinicon from "../assets/linkedinicon.svg"
import instaicon from "../assets/instaicon.svg"
import twittericon from "../assets/twittericon.svg"
import paypalbanner from "../assets/paypal_banner.png"
import apsbanner from "../assets/aps_banner.png"
import searchicon from "../assets/searchicon.svg"
import catImg1 from "../assets/catimg1.png"
import catImg2 from "../assets/catimg2.png"
import filtericon from "../assets/filtericon.svg"
import forwardIcon from "../assets/forwardicon.svg"
import backwardIcon from "../assets/backwardicon.svg"
import aboutusfig1 from "../assets/aboutusfig.png"
import profilefig from "../assets/ownerpic.png"
import removeIcon from "../assets/removeicon.svg"
import addIcon from "../assets/addicon.svg"
import deleteIcon from "../assets/deleteicon.svg"
import emptyCartIcon from "../assets/emptycart.svg"
import locationIcon from "../assets/locationicon.svg"
import cardPaymentIcons from "../assets/cardpaymentsicons.png"
import trustpilot from "../assets/trustpilot_logo.svg"
import navbarclose from "../assets/navbar_close.svg"
import homeicon from "../assets/home_icon.svg"
import abouticon from "../assets/about_icon.svg"
import trackicon from "../assets/track_icon.svg"
import contacticon from "../assets/contact_icon.svg"
import paysuccessicon from "../assets/paysuccess_icon.svg"
import payfailicon from "../assets/payfail_icon.svg"
import emptyImage from "../assets/empty_image.jpg"
import serverDownImage from "../assets/serverclock.png"
import image404 from '../assets/image_404.svg'

export const Images = {
    logo, logowhite, bgImage, cartImage, fbicon, linkedinicon, instaicon, twittericon, paypalbanner, apsbanner, searchicon,
    catImg1, catImg2, filtericon, forwardIcon, backwardIcon, aboutusfig1, profilefig, removeIcon, addIcon, deleteIcon,
    emptyCartIcon, locationIcon, cardPaymentIcons, trustpilot, navbarclose, homeicon, abouticon, trackicon, contacticon,
    paysuccessicon, payfailicon, emptyImage, serverDownImage, image404
}