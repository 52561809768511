import React, { useState } from 'react'
import "./searchbar.scss"
import { Images } from '../../utils/Images'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { setDropdownStatus, setSearchDetails, setSearchStatus, setSubCategoryData } from '../../store/ui.reducer'
import { useNavigate } from 'react-router-dom'
import { RouteStrings } from '../../utils/RouteStrings'

export const SearchBar = ({ category, isVisible }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [validated, setValidated] = useState(false);
    const [searchData, setSearchData] = useState({
        term: "",
        adesc: false,
        category_number: 0,
        sortby: "newlyUpdated",
        page: 1
    })

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            event.preventDefault();
            dispatch(setSearchStatus(true))
            dispatch(setDropdownStatus(false))
            navigate(`${RouteStrings.cataloguelist}?ct=${searchData.category_number == 0 ? 0 : category}&sch=${searchData.term}&desc=${searchData.adesc == false ? 0 : 1}`)
            setSearchData({
                term: "",
                adesc: false,
                category_number: 0,
                sortby: "newlyUpdated",
                page: 1
            })
            setValidated(false);
        }


    };
    return (
        <div className="search_sec">
            <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete='off'>
                <div className="d-flex justify-content-md-end gap-2">
                    <Form.Group controlId="validationCustom01" className='position-relative'>
                        <Form.Control
                            required
                            type="text"
                            className="search_input"
                            value={searchData.term}
                            onChange={(e) => setSearchData({ ...searchData, term: e.target.value })}
                        />
                        <Form.Control.Feedback type='invalid' className='error_msg'>Enter text</Form.Control.Feedback>
                    </Form.Group>
                    <button type='submit' className='search_div bg-transparent'>
                        <img src={Images.searchicon} alt="icon" className='search_icon' />
                    </button>
                </div>
                <div className="flex-container d-block d-md-flex">
                    <div className='d-flex'>
                        <Form.Check
                            className='flex-item me-3'
                            label="Whole Catalogue"
                            name="group1"
                            type="radio"
                            id="radio1"
                            value={'0'}
                            onChange={(e) => setSearchData({ ...searchData, category_number: Number(e.target.value) })}
                            checked={searchData.category_number == 0 ? true : false}
                        />
                        {isVisible &&
                            <Form.Check
                                className='flex-item'
                                label="This Category"
                                name="group1"
                                type="radio"
                                id="radio2"
                                value={'1'}
                                onChange={(e) => setSearchData({ ...searchData, category_number: Number(e.target.value) })}
                                checked={searchData.category_number == 0 ? false : true}
                            />}
                    </div>

                    <Form.Check
                        className='flex-item flex_check mt-3 mt-md-0'
                        label="Search by description"
                        name="description"
                        type="checkbox"
                        id="checkbox3"
                        onChange={(e) => setSearchData({ ...searchData, adesc: e.target.checked })}
                        checked={searchData.adesc}
                    />
                </div>
            </Form>

        </div>
    )
}
