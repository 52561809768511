import { useEffect, useState } from 'react';
import './App.css';
import { Footer } from './components/Footer/Footer';
import { Header } from './components/Header/Header';
import { RouteCheck } from './routes/RouteCheck';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { ApiResponseHook } from './apis/Services';
import { configUrl } from './apis/api.config';
import { setToast } from './store/ui.reducer';
import { NavLink } from 'react-router-dom';
import { RouteStrings } from './utils/RouteStrings';
import { ToastBar } from './components/Toast/ToastBar';

function App() {
  const { loader, toast } = useSelector((state) => state.localStorage)
  const { getRequest } = ApiResponseHook()
  const dispatch = useDispatch()

  const [width, setWidth] = useState(window.innerWidth);
  const [note, setNote] = useState("")

  useEffect(() => {
    getLatestNote()
    getCatelogueTypes()
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  // latest note update 
  const getLatestNote = async () => {
    let response = await getRequest(configUrl.getlatestNote)
    setNote(response?.data?.data?.iptxt ? response?.data?.data?.iptxt : "")
  }

  const [categories, setCategories] = useState([])

  // get collectable item types
  const getCatelogueTypes = async () => {
    let response = await getRequest(configUrl.getCatalogues)
    let catalogueTypeData = response?.data?.data ? response?.data?.data : []
    setCategories(catalogueTypeData)
    // let savedata = catalogueTypeData
    // let savedata = []
    // if (!cataloguesItem) {
    //     savedata = catalogueTypeData
    // } else {
    //     let _catalogueitem = cataloguesItem.filter(item => item.isActive)
    //     if (_catalogueitem.length > 0) {
    //         let catalogueitem = catalogueTypeData.map(item => {
    //             item = { ...item, isActive: (item.category == _catalogueitem[0].category ? true : false) }
    //             return item
    //         })
    //         savedata = catalogueitem
    //     } else {
    //         savedata = catalogueTypeData
    //     }
    // }
    // dispatch(setCatalogueItems(savedata))
  }

  return (
    <div className='appdiv'>
      {note && <div className='note_bar'>
        <div className='note_status'>NOTE :<span className='ms-2'>{note}</span></div>
      </div>}
      <Header screenWidth={width} />
      <div>
        <RouteCheck screenWidth={width} categories={categories} />
      </div>
      <Footer />
      {loader && <div className='loader_style'>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>}
      <ToastBar show={toast} status={true}
        msg={<>The selected item has been added to the cart, <NavLink to={RouteStrings.viewbasket}
          className="text-white text-decoration-underline" onClick={() => dispatch(setToast(false))}>click to view</NavLink></>} />
    </div>
  );
}

export default App;
