import React, { useState } from 'react'
import './filterbar.scss'
import { Images } from '../../utils/Images'
import { Link } from 'react-router-dom'

export const FilterBar = ({ buttons, onClick, value }) => {

    return (
        <div className='filterBar'>
            <div className="d-none d-md-block">
                {buttons.map((button, index) => (
                    // <button
                    //     key={index}
                    //     className={`btn btn_filter ${button.isActive ? "isActive" : ""}`}
                    //     onClick={() => onClick(button.id, button.apiValue)}
                    // >
                    //     {button.text}
                    // </button>
                    <Link
                        key={index}
                        className={`btn btn_filter ${button.isActive ? "isActive" : ""}`}
                        to={onClick(button.id, button.apiValue)}
                    >
                        {button.text}
                    </Link>
                ))}
            </div>
            <div className="d-flex d-md-none align-items-center justify-content-center">
                <img src={Images.filtericon} alt="icon" />
                <strong className='mb-0 ms-1 me-3'>Filters</strong>
                <select name="filter_options" id="filter_options" className='filteroption'
                    onChange={(e) => { onClick("", e.target.value) }} value={value}>
                    {buttons.map((button, index) => (
                        <option value={button.apiValue} key={index}>{button.text}</option>
                    ))}
                </select>
            </div>
        </div >
    )
}
