import { createSlice } from "@reduxjs/toolkit";


export const uiReducer = createSlice({
    name: "uiReducer",
    initialState: {
        productDetail: null,
        loader: false,
        basketOrder: [],
        toast: false,
        isSearch: false,
        searchDetail: null,
        isDropdownStatus: false,
        subCategoryData: null,
        trackOrderdata: null,
        paymentItem: null,
        orderDetailStatus: false,
        activeCatalogueType: null,
    },
    reducers: {
        increment: (state) => {
            state.value += 1;
        },
        decrement: (state) => {
            state.value -= 1;
        },
        setProductDetail: (state, action) => {
            state.productDetail = action.payload;
        },
        setLoader: (state, action) => {
            state.loader = action.payload;
        },
        setBasketOrder: (state, action) => {
            state.basketOrder = action.payload;
        },
        setToast: (state, action) => {
            state.toast = action.payload;
        },
        setSearchStatus: (state, action) => {
            state.isSearch = action.payload;
        },
        setSearchDetails: (state, action) => {
            state.searchDetail = action.payload;
        },
        setDropdownStatus: (state, action) => {
            state.isDropdownStatus = action.payload;
        },
        setSubCategoryData: (state, action) => {
            state.subCategoryData = action.payload;
        },
        setTrackOrderData: (state, action) => {
            state.trackOrderdata = action.payload;
        },
        setPaymentItem: (state, action) => {
            state.paymentItem = action.payload;
        },
        setOrderDetailStatus: (state, action) => {
            state.orderDetailStatus = action.payload;
        },
        setActiveCatalogueType: (state, action) => {
            state.activeCatalogueType = action.payload;
        },
        resetSearch: (state, action) => {
            state.searchDetail = null;
            state.isSearch = false;
            state.isDropdownStatus = false;
            state.subCategoryData = null;
        }
    },
});

export const uiSessionReducer = createSlice({
    name: "uiReducer",
    initialState: {
        shopBehalfStatus: false,
        shopbehalfData: null,
        sbcparams: null,
    },
    reducers: {
        increment: (state) => {
            state.value += 1;
        },
        decrement: (state) => {
            state.value -= 1;
        },
        setShopBehalfCust: (state, action) => {
            state.shopBehalfStatus = action.payload.sbs;
            state.shopbehalfData = action.payload.sbcdata;
            state.sbcparams = action.payload.sbcparams;
        }
    },
});

// Action creators are generated for each case reducer function
export const { increment, decrement, setProductDetail, setLoader, setBasketOrder, setTrackOrderData,
    setToast, setSearchStatus, setSearchDetails, setDropdownStatus, setSubCategoryData, setPaymentItem, setOrderDetailStatus,
    setActiveCatalogueType, resetSearch } = uiReducer.actions;
export const { setShopBehalfCust } = uiSessionReducer.actions;

export default { uiReducer, uiSessionReducer };
