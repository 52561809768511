import React, { useEffect, useRef, useState } from 'react'
import './productdetail.scss'
import { Button, Modal } from 'react-bootstrap'
import { Images } from '../../utils/Images'
import { resetSearch, setActiveCatalogueType, setBasketOrder, setProductDetail, setToast } from '../../store/ui.reducer'
import { useDispatch, useSelector } from 'react-redux'
import { configUrl } from '../../apis/api.config'
import { ApiResponseHook } from '../../apis/Services'
import { useLocation, useNavigate } from 'react-router-dom'
import { RouteStrings } from '../../utils/RouteStrings'
import { Categorybar } from '../../components/CategoryBar/Categorybar'
import { Helmet } from 'react-helmet'
import Upscaler from 'upscaler';

export const ProductDetail = ({ categories }) => {
    const detailPositionRef = useRef(null)
    const location = useLocation()

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { productDetail, basketOrder, activeCatalogueType } = useSelector((state) => state.localStorage)
    let _basketOrder = [...basketOrder]
    const { getRequest } = ApiResponseHook()

    const [detailData, setDetailData] = useState({})
    const [currentIndex, setCurrentIndex] = useState(0);
    const [subCategory, setSubCategories] = useState([])
    const [imagesModal, setImagesModal] = useState(false)
    const [packageCategory, setPackageCategory] = useState([])

    const queryParams = new URLSearchParams(location.search);
    const pgno = queryParams.get('pg');
    const ctvalue = queryParams.get('ft');
    const testact = queryParams.get('ct');
    const paramSearch = queryParams.get('sch');
    const schdesc = queryParams.get('desc');
    const paramdrop = queryParams.get('sub');
    const detailItem = queryParams.get('item');
    const homescreen = queryParams.get('home');
    const basket = queryParams.get('bask');

    useEffect(() => {
        getProductDetailData(detailItem)
        getPackageCategory()
    }, [detailItem, testact])

    //  get subcategories 
    const getSubCategories = async (actcatalogue) => {
        if (Number(actcatalogue) > 0) {
            let response = await getRequest(`${configUrl.getDropdownOptions}${actcatalogue}/`)
            setSubCategories(response?.data?.data ? response?.data?.data : [])
        }
    }

    // get direct packaging items category 
    const getPackageCategory = async () => {
        let response = await getRequest(configUrl.getPackageCategories)
        setPackageCategory(response?.data?.data || [])
    }

    // get productdetailData 
    const getProductDetailData = async (itemId) => {
        if (itemId) {
            let response = await getRequest(`${configUrl.getProductDetailById}${itemId}/`)
            getSubCategories(response?.data?.productdetail?.category || '')
            setDetailData(response?.data?.productdetail ? response?.data?.productdetail : [])
        }
    }
    // for image scroll
    const goToPreviousImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? detailData.image.length - 1 : prevIndex - 1));
    };
    const goToNextImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex === detailData.image.length - 1 ? 0 : prevIndex + 1));
    };

    // add to cart
    const handleCartClick = (data) => {
        if (!(_basketOrder.includes(data.sysid))) {
            _basketOrder.push(data.sysid)
            dispatch(setBasketOrder(_basketOrder))
        }
        dispatch(setToast(true))
    }

    // back to view list
    const handleBackToList = () => {
        if (homescreen) {
            navigate(`${RouteStrings.home}?ft=${ctvalue}&pg=${pgno}`)
        } else if (basket) {
            navigate(`${RouteStrings.cataloguelist}?ct=${testact}`)
        } else if (paramSearch) {
            navigate(`${RouteStrings.cataloguelist}?ct=${testact}&ft=${ctvalue}&sch=${paramSearch}&desc=${schdesc}&pg=${pgno}`)
        } else if (paramdrop) {
            navigate(`${RouteStrings.cataloguelist}?ct=${testact}&ft=${ctvalue}&sub=${paramdrop}&pg=${pgno}`, { state: location.state })
        } else {
            navigate(`${RouteStrings.cataloguelist}?ct=${detailData.category}&ft=${ctvalue || "newltyUpdated"}&pg=${pgno || 1}`)
        }
    }


    /**
     * upgrade images
     */

    const [originalImage, setOriginalImage] = useState(null);
    const [upscaledImage, setUpscaledImage] = useState(null);
    console.log("🚀 ~ ProductDetail ~ upscaledImage:", upscaledImage)

    // const handleImageUrlChange = (event) => {
    //     setImageUrl(event.target.value);
    // };
    useEffect(() => {
        upscaleImage()
    }, [originalImage])

    const fetchImage = async (imageUrl) => {
        if (!imageUrl) return;

        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const reader = new FileReader();

        reader.onloadend = () => {
            setOriginalImage(reader.result);
        };

        reader.readAsDataURL(blob);
    };

    const upscaleImage = async () => {
        if (!originalImage) return;

        const upscaler = new Upscaler();
        const img = new Image();
        img.src = originalImage;
        img.onload = async () => {
            const tensor = await upscaler.upscale(img);
            const canvas = document.createElement('canvas');
            canvas.width = tensor.shape[1];
            canvas.height = tensor.shape[0];
            const ctx = canvas.getContext('2d');
            await ctx.drawImage(await tensor.toPixels(), 0, 0);
            setUpscaledImage(canvas.toDataURL());
        };
    };

    return (
        <>
            <div className='product_detail'>
                <Helmet>
                    <meta name="description"
                        content={`${detailData?.description}`} />
                    <link rel="canonical" href={`https://pennymead.com/productdetail?item=${detailItem}&ct=${testact || ""}&ft=${ctvalue || ""}&sch=${paramSearch || ""}&desc=${schdesc || ""}&sub=${paramdrop || ""}&pg=${pgno}`} />
                    <title>{detailData?.author}</title>
                </Helmet>
                <div className="container">
                    <div className="mb-5" ref={detailPositionRef}>
                        <Categorybar categories={categories} subCategory={subCategory}
                            activeCatalogueType={detailData.category} subcategoryVisible={true} />
                    </div>

                    {Object.keys(detailData).length > 0 &&
                        <>
                            <div className="row">
                                <div className="col-md-8 my-auto">
                                    <div className="card mb-3 productdetailcard">
                                        <div className="row">
                                            <div className="col-md-5 mb-4 my-md-auto">
                                                {detailData.image.length > 0 ?
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div>
                                                            <img src={Images.backwardIcon} alt="icon" onClick={goToPreviousImage}
                                                                className={`me-2 cursor-pointer ${currentIndex == 0 ? "disabled_icon" : ""}`} />
                                                        </div>
                                                        <div className='position-relative'>
                                                            <img src={detailData.image[currentIndex]} className="img-fluid productimg" onClick={() => {
                                                                setImagesModal(true)
                                                                fetchImage(detailData.image[currentIndex])
                                                            }}
                                                                alt={`${detailData.author} ${detailData.title}`} />
                                                            <button type="button" className='enlarge_btn' onClick={() => setImagesModal(true)}>Click to Enlarge</button>
                                                        </div>
                                                        <div>
                                                            <img src={Images.forwardIcon} alt="icon" onClick={goToNextImage}
                                                                className={`ms-2 cursor-pointer ${currentIndex == (detailData.image.length - 1) ? "disabled_icon" : ""}`} />
                                                        </div>
                                                    </div> : <div>
                                                        <img src={Images.emptyImage} alt="icon" className='img-fluid' />
                                                    </div>}
                                            </div>
                                            <div className="col-md-7">
                                                <div className="card-body">
                                                    <h2>{detailData.author}</h2>
                                                    <p className="card-text1">{detailData.author2}</p>
                                                    <p className="card-text2">{detailData.title}</p>
                                                    <p className="card-text2">{detailData.longtitle}</p>
                                                    <p className="card-text2">{detailData.publisher} {detailData.pubdate && `(${detailData.pubdate})`}</p>
                                                    <p className="card-text2">{detailData.description}</p>
                                                    <p className="card-text2">{detailData.footnote}</p>
                                                    <p className='cartsymboldiv mt-3'><span className='cart_price_symbol'>&pound;</span> <span className='cart_price'>{detailData.price}</span></p>
                                                    {detailData.quantity > 0 && <div className='text-end'>
                                                        <Button className='cart_button' onClick={() => { handleCartClick(detailData) }}>Add to cart</Button>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-4 my-auto">
                                    <div className='mb-4'>
                                        <p className="mb-0"><strong>Shipping & Delivery</strong></p>
                                        {packageCategory.includes(detailData.category) ? <p className="card-text2"> Post and packing is extra but does not increase however many items are ordered.Inland &pound;1, Europe and Overseas &pound;3</p> :
                                            <>
                                                <p className="card-text2">Books, maps and prints are carefully described but if you have particular concerns please e-mail enquires to me.</p>
                                                <p className="card-text2">Post and packing is extra.  Orders are packed carefully and covered by my insurance.   Costs are kept to a  minimum.  After you have placed your order you will receive an email invoice showing the total including post and packing. If you feel this is too much you can cancel the order without any problem.</p>
                                            </>}
                                    </div>
                                </div>
                            </div>
                            <div className="text-end mt-5 pb-5 me-md-4">
                                <Button className='cart_button' onClick={() =>
                                    handleBackToList()
                                }>{(ctvalue || paramSearch || paramdrop) ? "Back to list view" : "View all"}</Button>
                                {/* {basket ? "View all" : "Back to list view"} */}

                            </div></>}
                </div>
            </div>

            {/* <Modal
                size="xl"
                show={imagesModal}
                onHide={() => setImagesModal(false)}
                centered
            >
                <Modal.Body>
                    {Object.keys(detailData).length > 0 &&
                        <div className='d-flex align-items-center justify-content-between'>
                            <div>
                                <img src={Images.backwardIcon} alt="icon" onClick={goToPreviousImage}
                                    className={`me-2 cursor-pointer ${currentIndex == 0 ? "disabled_icon" : ""}`} />
                            </div>
                            <div>
                                <img src={detailData.image[currentIndex]} className="img-fluid" alt="..." onClick={() => setImagesModal(true)} />
                            </div>
                            <div>
                                <img src={Images.forwardIcon} alt="icon" onClick={goToNextImage}
                                    className={`ms-2 cursor-pointer ${currentIndex == (detailData.image.length - 1) ? "disabled_icon" : ""}`} />
                            </div>
                        </div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setImagesModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal> */}

            {/** testing purpose */}
            <Modal
                size="xl"
                show={imagesModal}
                onHide={() => setImagesModal(false)}
                centered
            >
                <Modal.Body>
                    {upscaledImage && <>
                        <img src={upscaledImage} className="img-fluid productimg" onClick={() => {
                            setImagesModal(true)
                            // fetchImage(detailData.image[currentIndex])
                        }} /></>}
                    {Object.keys(detailData).length > 0 &&
                        <div className='d-flex align-items-center justify-content-between'>
                            <div>
                                <img src={Images.backwardIcon} alt="icon" onClick={goToPreviousImage}
                                    className={`me-2 cursor-pointer ${currentIndex == 0 ? "disabled_icon" : ""}`} />
                            </div>
                            <div>
                                <img src={detailData.image[currentIndex]} className="img-fluid" alt="..." onClick={() => setImagesModal(true)} />
                            </div>
                            <div>
                                <img src={Images.forwardIcon} alt="icon" onClick={goToNextImage}
                                    className={`ms-2 cursor-pointer ${currentIndex == (detailData.image.length - 1) ? "disabled_icon" : ""}`} />
                            </div>
                        </div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setImagesModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
