import React, { useEffect, useState } from 'react'
import './contactpage.scss'
import { ApiResponseHook } from '../../apis/Services'
import { configUrl } from '../../apis/api.config'
import { Categorybar } from '../../components/CategoryBar/Categorybar'
import { Helmet } from 'react-helmet'

export const ContactPage = ({ categories }) => {
    const { getRequest } = ApiResponseHook()

    const [contactData, setContactData] = useState({})

    useEffect(() => {
        getContactData()
    }, [])
    const getContactData = async () => {
        let response = await getRequest(configUrl.getContactInfo)
        let responseData = response?.data?.data ? response?.data?.data : []
        setContactData(responseData.length > 0 ? responseData[0] : {})
    }


    return (
        <div className='contactpage'>
            <Helmet>
                <meta name="description"
                    content="Pennymead Auctions/Books is a one man business which I started in 1982.  I trade from my home in Knaresborough and welcome customers by appointment.All goods are described accurately and in good faith.  Any found not to be as described may be returned for a full refund" />
                <link rel="canonical" href="https://pennymead.com/contactus" />
                <title>Contact Us</title>
            </Helmet>
            <div className="container">
                <div className="mb-5">
                    <Categorybar categories={categories} subCategory={[]}
                        subcategoryVisible={false} />

                    <div className='aboutdata'>
                        <div className='mb-4'>
                            <h4 className='mb-3'>Contact Information</h4>
                            <div dangerouslySetInnerHTML={{ __html: contactData?.contact_info }} />
                        </div>
                        <div className='mb-4'>
                            <h4 className='mb-3'>Terms and Conditions</h4>
                            <div dangerouslySetInnerHTML={{ __html: contactData?.terms_conditions }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
