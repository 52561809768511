import React from 'react'
import { Card } from 'react-bootstrap'
import './cartcard.scss'
import { Link } from 'react-router-dom'

export const RelateCard = (props) => {
    const { cartbookAuthor, cartPrice, cartImg, cartText1, cartText2, onClick, cat_name, link_to } = props
    return (
        <>
            <Card className="related_card">
                {cat_name && <p className='mb-1 bg-rel'><strong>Related items available in {cat_name}</strong></p>}
                <Card.Img variant="top" src={cartImg} className="img-fluid rel_img mb-2" alt={`${cartbookAuthor} ${cartText1}`} />
                <p className='mb-1'><strong>{cartbookAuthor}</strong> <span>{cartText1} {cartText2}</span></p>
                <p className='mb-1'><strong>&pound; {cartPrice}</strong></p>

                {/* <p className="mt-1 mb-1 text-decoration-underline text-primary cursor_pointer" onClick={() => onClick()}>
                    See More
                </p> */}
                <Link to={link_to} className='my-1'>See More</Link>
            </Card>
        </>
    )
}
