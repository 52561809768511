import React, { useEffect, useState } from 'react'
import "./aboutus.scss"
import { Card } from 'react-bootstrap'
import { resetSearch, setActiveCatalogueType } from '../../store/ui.reducer'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { RouteStrings } from '../../utils/RouteStrings'
import { ApiResponseHook } from '../../apis/Services'
import { configUrl } from '../../apis/api.config'
import { Images } from '../../utils/Images'
import { Helmet } from 'react-helmet'

export const AboutUs = ({ categories }) => {
    const [content, setContent] = useState({})
    const { getRequest } = ApiResponseHook()

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        getContent()

    }, [])


    // get Cms content
    const getContent = async () => {
        let response = await getRequest(configUrl.getAboutPageContent)
        let responseData = response?.data?.data ? response?.data?.data : []
        setContent(responseData.length > 0 ? responseData[0] : {})
    }

    // navigating to cataloguelist page
    const handleCatalogueList = (category) => {
        dispatch(setActiveCatalogueType(category))
        navigate(`${RouteStrings.cataloguelist}?ct=${category}`)
    }

    return (
        <div className='aboutus home_page'>
            <Helmet>
                <meta name="description"
                    content="Vintage Enthusiasts Rejoice: Uncover Hidden Gems in our website. Our auction showcases a diverse range of vintage collectables, each with its own unique allure. From elegant Victorian-era pieces to mid-century modern marvels, there's something for everyone." />
                <link rel="canonical" href="https://pennymead.com/aboutus" />
                <title>About Us</title>
            </Helmet>
            <div className="container">
                {Object.keys(content).length > 0 && <>
                    <div className="row">
                        <div className="col-md-6 my-auto">
                            <div>
                                <div dangerouslySetInnerHTML={{ __html: content?.header }} />
                                <div dangerouslySetInnerHTML={{ __html: content?.about_pennymead }} />
                            </div>
                        </div>
                        <div className="col-md-6 my-auto">
                            <div className='text-center'>
                                <img src={content?.about_image} alt="vintagefig" className='img-fluid abt_img' />
                            </div>
                        </div>
                    </div>
                    <div className="row my-5 py-5">
                        <div className="col-md-6 col-lg-4 my-auto">
                            <div className='prof_design'>
                                <img src={content?.admin_image} alt="Admin Picture" className='img-fluid' />
                                <div className="topsmall_box"></div>
                                <div className="bottomsmall_box"></div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-8 my-auto">
                            <div>
                                <div className='mb-1' dangerouslySetInnerHTML={{ __html: content?.admin_info }} />
                                <div className='mb-1' dangerouslySetInnerHTML={{ __html: content?.about_admin }} />
                            </div>
                        </div>
                    </div>
                </>}

                <div className='my-5'>
                    <h2>Select from Collectables</h2>

                    <div className="mx-lg-5 mt-4">
                        <div className="row">
                            {categories?.length > 0 && categories.map((item, i) => {
                                return (
                                    <div className="col-6 col-md-4 my-2 my-md-4 px-md-4" key={i}>
                                        <Card className="category_card">
                                            <Link to={`${RouteStrings.cataloguelist}?ct=${item.category}`} className='category_img_div'>
                                                <Card.Img variant="top" src={(item.image && item.image.length > 0) ? item.image[0] : Images.emptyImage} className="img-fluid category_img" />
                                            </Link>
                                            {/* <Card.Img variant="top" src={(item.image && item.image.length > 0) ? item.image[0] : Images.emptyImage} className="img-fluid category_img"
                                                onClick={() => handleCatalogueList(item.category)} alt={`${item.name} ${item.title}`} /> */}
                                            <div className='text-center'>
                                                <p className='card_text'>
                                                    {item.title}
                                                </p>
                                                {/* <p className='catalogue_name' onClick={() => handleCatalogueList(item.category)}>{item.name}</p> */}
                                                <div className='catalogue_link_div'>
                                                    <Link className='catalogue_name' to={`${RouteStrings.cataloguelist}?ct=${item.category}`}>{item.name}</Link>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div >

            </div>
        </div>
    )
}
