import React, { useEffect, useRef } from 'react'
import "./footer.scss"
import { Images } from '../../utils/Images'
import { RouteStrings } from '../../utils/RouteStrings'
import { NavLink } from 'react-router-dom'

export const Footer = () => {

    const ref = useRef(null);
    useEffect(() => {
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);
    return (
        <div className='footer_sec'>
            <div className="container">
                <div className="row">
                    <div className="col-6 col-md mb-5 my-md-0 order-0">
                        <div className='text-center text-md-start'>
                            <img src={Images.paypalbanner} alt="paypal" className='img-fiuid footer_img paypal' />
                        </div>
                    </div>
                    <div className="col-md order-2 order-md-1">
                        <div className='text-center'>
                            <img src={Images.logowhite} alt="logo" className='img-fluid mb-4 d-none d-md-block mx-auto' />
                            <p className='text-white mb-2'>Follow us on</p>
                            <a href="https://www.facebook.com/pennymeadbooks/" target='_blank'><img src={Images.fbicon} alt="icon" className='socialicon' /></a>
                        </div>
                    </div>
                    <div className="col-6 col-md mb-5 my-md-0 order-1">
                        <div className="w-180 ms-auto">
                            <div className='text-center'>
                                <img src={Images.apsbanner} alt="paypal" className='img-fluid footer_img' />
                                <div className='mt-2'>
                                    <a href="https://www.trustpilot.com/review/pennymead.com" target="_blank" className='d-block' >
                                        <img src={Images.trustpilot} alt="paypal" className='img-fluid trust_pilot pb-2' />
                                    </a>
                                    <div
                                        ref={ref}
                                        className="trustpilot-widget"
                                        data-locale='en-GB'
                                        data-template-id='5419b6a8b0d04a076446a9ad'
                                        data-businessunit-id='5a4cb3e1b894c90d882a0738'
                                        data-style-height='100px' data-style-width='100%' data-theme='light'
                                    >
                                        <a href="https://www.trustpilot.com/review/pennymead.com" target="_blank" rel="noopener">
                                            Trustpilot
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='text-center mt-4 mt-md-5 pt-3'>
                    <p className='termsprivacy'>Copyright © 2023, Pennymead | All Rights Reserved |
                        <NavLink to={RouteStrings.contactus} className={"termsprivacy mx-2"}>Terms & Conditions</NavLink> |
                        <NavLink to={RouteStrings.contactus} className={"termsprivacy mx-2"}>Privacy Policy</NavLink>
                    </p>
                </div>
            </div>
        </div>
    )
}
