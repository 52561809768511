import React, { useEffect, useRef, useState } from 'react'
import "./home.scss"
import { Card, Pagination } from 'react-bootstrap'
import { FilterBar } from '../../components/FilterBar/FilterBar'
import { CartCard } from '../../components/CartCard/CartCard'
import { ApiResponseHook } from '../../apis/Services'
import { configUrl } from '../../apis/api.config'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { RouteStrings } from '../../utils/RouteStrings'
import { useDispatch, useSelector } from 'react-redux'
import {
    resetSearch, setActiveCatalogueType, setBasketOrder, setProductDetail, setShopBehalfCust, setToast
} from '../../store/ui.reducer'
import { Images } from '../../utils/Images'
import { Helmet } from 'react-helmet'

export const Home = ({ screenWidth, categories }) => {
    const collectableItemRef = useRef(null)


    const [catalogues, setCatalogues] = useState([])
    const [filterValue, setFilterValue] = useState("newlyUpdated")
    const [catalogueData, setCatalougueData] = useState({})
    const [pageNumber, setPageNumber] = useState(1)
    const [goto, setGoto] = useState('')
    const [filterButtonValue, setFilterButtonValue] = useState('')

    const { basketOrder } = useSelector(state => state.localStorage)

    let _basketOrder = [...basketOrder]
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { getRequest } = ApiResponseHook()
    const [buttons, setButtons] = useState([
        { id: 1, text: 'Newest items', isActive: true, apiValue: "newlyUpdated" },
        { id: 2, text: 'Author or date', isActive: false, apiValue: "author" },
        // { id: 3, text: 'Title', isActive: false, apiValue: "title" },
        { id: 4, text: 'High to low price', isActive: false, apiValue: "price_high" },
        { id: 5, text: 'Low to high price', isActive: false, apiValue: "price_low" },
    ]);
    const filterArray = ['newlyUpdated', 'author', 'price_high', 'price_low']
    useEffect(() => {
        setCatalogues(categories)
    }, [categories])

    const queryParams = new URLSearchParams(location.search);
    const pgno = queryParams.get('pg');
    const ctvalue = queryParams.get('ft');
    const _ctValue = filterArray.includes(ctvalue) ? ctvalue : "newlyUpdated"
    useEffect(() => {
        setFilterValue(_ctValue)
        setPageNumber(pgno ? Number(pgno) : 1)
        getAllCatelogues(_ctValue, pgno || 1)
        const updatedButtons = buttons.map((button) =>
            (button.apiValue === _ctValue)
                ? { ...button, isActive: true }
                : { ...button, isActive: false }
        );
        setButtons(updatedButtons);
        categoryDropdownMob(_ctValue)
    }, [pgno, _ctValue])

    // filter for mobile view
    const categoryDropdownMob = (dropdownValue) => {
        let defaultvalue = buttons.filter(item => item.apiValue === dropdownValue)
        setFilterButtonValue(defaultvalue[0].apiValue)
    }

    // check for behalf of customer
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const shopBehalfCustomer = queryParams.get('sbofc');

        try {
            const decodedData = JSON.parse(atob(shopBehalfCustomer));
            verifyCustomer(decodedData, shopBehalfCustomer)
        } catch (error) {
            dispatch(setShopBehalfCust({ sbs: false, sbcdata: null, sbcparams: null }))
        }
    }, [])

    //  verify the customer
    const verifyCustomer = async (data, paramdata) => {
        let response = await getRequest(`${configUrl.verifyCustomer}${data.sbofc}/`)
        if (response.status === 200) {
            const encodedData = btoa(JSON.stringify(response.data.data[0].email));
            dispatch(setShopBehalfCust({ sbs: true, sbcdata: encodedData, sbcparams: paramdata }))
        }
    }

    // get stock items 
    const getAllCatelogues = async (value, page) => {
        let response = await getRequest(`${configUrl.getAllCateloguesRandom}${value}/${page}/`)
        setCatalougueData(response?.data?.data ? response?.data?.data : {})
    }

    // filtering the collectable items
    const handleButtonClick = (buttonId, buttonApiValue) => {
        navigate(`${RouteStrings.home}?ft=${buttonApiValue}`)
    };
    const _handleButtonClick = (buttonId, buttonApiValue) => {
        return `${RouteStrings.home}?ft=${buttonApiValue}`
    };

    // pagination section 
    const handlePageNumber = (id) => {
        if (id && !(Number(id) > catalogueData.totalpages || Number(id) <= 0)) {
            navigate(`${RouteStrings.home}?ft=${filterValue}&pg=${id}`)
            collectableItemRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    // navigate to catalogue list page 
    const handleCatalogueList = (category) => {
        navigate(`${RouteStrings.cataloguelist}?ct=${category}`)
    }

    // product detail of individual item 
    const handleProductDetail = (data) => {
        navigate(`${RouteStrings.productdetail}?home=1&item=${data.sysid}&ft=${filterValue}&pg=${pageNumber}`)
    }
    const _handleProductDetail = (data) => {
        return `${RouteStrings.productdetail}?home=1&item=${data.sysid}&ft=${filterValue}&pg=${pageNumber}`
    }

    // add to cart
    const handleCartClick = (data) => {
        if (!(_basketOrder.includes(data.sysid))) {
            _basketOrder.push(data.sysid)
            dispatch(setBasketOrder(_basketOrder))
        }
        dispatch(setToast(true))
    }

    return (
        <div className='home_page'>
            <Helmet>
                <meta name="description"
                    content="Books on philately for sale.  I mostly stock out of print titles but act as agent for the West Indies Philatelic Study Group" />
                <link rel="canonical" href="https://pennymead.com/" />
                <title>Pennymead</title>
            </Helmet>
            <div className='container'>
                <div className='mt-3 mb-4 mb-md-5'>
                    <h2>Select from Collectables</h2>

                    <div className="mx-lg-5 mt-4">
                        <div className="row">
                            {catalogues.length > 0 && catalogues.map((item, i) => {
                                return (
                                    <div className="col-6 col-md-4 my-2 my-md-4 px-md-4" key={i}>
                                        <Card className="category_card">
                                            <Link to={`${RouteStrings.cataloguelist}?ct=${item.category}`} className='category_img_div'>
                                                <Card.Img variant="top" src={(item.image && item.image.length > 0) ? item.image[0] : Images.emptyImage} className="img-fluid category_img" />
                                            </Link>
                                            {/* <Card.Img variant="top" src={(item.image && item.image.length > 0) ? item.image[0] : Images.emptyImage} className="img-fluid category_img"
                                                onClick={() => handleCatalogueList(item.category)} alt={`${item.name} ${item.title}`} /> */}


                                            <div className='text-center mt-3'>
                                                <p className='card_text'>
                                                    {item.title}
                                                </p>
                                                {/* <p className='catalogue_name' onClick={() => handleCatalogueList(item.category)}>{item.name}</p> */}
                                                <div className='catalogue_link_div'>
                                                    <Link className='catalogue_name' to={`${RouteStrings.cataloguelist}?ct=${item.category}`}>{item.name}</Link>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div >

                <div ref={collectableItemRef} className="my-5">
                    <h2>Latest items added from all categories</h2>
                    {Object.keys(catalogueData).length > 0 ? <>
                        <div className="filter_sec">
                            <FilterBar buttons={buttons} onClick={_handleButtonClick} value={filterButtonValue} />
                        </div>
                        <div className='row'>
                            {catalogueData.data && catalogueData.data.map((item, i) => {
                                return (
                                    <div className="col-md-4 col-xl-3 my-4" key={i}>
                                        <CartCard cartImg={(item.image && item.image.length > 0) ? item.image[0] : Images.emptyImage} cartbookAuthor={item.author} cartPrice={item.price}
                                            cartText1={item.title} cartText2={item.description} onClick={() => { }}
                                            link_to={_handleProductDetail(item)} cartbookAuthor2={item.author2}
                                            onCartClick={() => handleCartClick(item)} publisher={item.publisher} pubDate={item.pubdate} />
                                    </div>
                                )
                            })}
                        </div>

                        <div className='pagination_style d-lg-flex'>
                            <Pagination>
                                <Pagination.Prev
                                    onClick={(e) => handlePageNumber(pageNumber - 1)}
                                    disabled={catalogueData.hasprevious ? (catalogueData.hasprevious ? false : true) : true} />
                                {screenWidth < 768 ?
                                    <>
                                        {pageNumber >= 3 ? <>
                                            <Pagination.Item id={"1"} onClick={(e) => handlePageNumber(e.target.id)}>{1}</Pagination.Item>

                                            {pageNumber == 3 && <Pagination.Item id={pageNumber - 1} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber - 1}</Pagination.Item>}
                                            {pageNumber !== 3 && <Pagination.Ellipsis />}
                                        </> : <>
                                            {pageNumber == 2 ? <Pagination.Item id={pageNumber - 1} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber - 1}</Pagination.Item> : null}</>}

                                    </>
                                    : <>
                                        {pageNumber >= 4 ? <>
                                            <Pagination.Item id={"1"} onClick={(e) => handlePageNumber(e.target.id)}>{1}</Pagination.Item>
                                            {pageNumber !== 4 && <Pagination.Ellipsis />}
                                            <Pagination.Item id={pageNumber - 2} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber - 2}</Pagination.Item>
                                            <Pagination.Item id={pageNumber - 1} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber - 1}</Pagination.Item></> :
                                            <>{pageNumber == 3 ? <><Pagination.Item id={pageNumber - 2} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber - 2}</Pagination.Item>
                                                <Pagination.Item id={pageNumber - 1} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber - 1}</Pagination.Item></> : <>
                                                {pageNumber == 2 ? <Pagination.Item id={pageNumber - 1} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber - 1}</Pagination.Item> : null}</>}
                                            </>}</>}

                                {screenWidth < 768 ? <>
                                    {pageNumber >= (catalogueData.totalpages - 2) && <>
                                        {pageNumber == catalogueData.totalpages ? <><Pagination.Item id={pageNumber - 2} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber - 2}</Pagination.Item>
                                            <Pagination.Item id={pageNumber - 1} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber - 1}</Pagination.Item></> : <>
                                            {pageNumber == (catalogueData.totalpages - 1) ? <>
                                                <Pagination.Item id={pageNumber - 1} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber - 1}</Pagination.Item></> : null}
                                        </>}
                                    </>}
                                </> : null}
                                <Pagination.Item active>{pageNumber}</Pagination.Item>
                                {screenWidth < 768 ? <>
                                    {pageNumber <= 3 && <>
                                        {pageNumber <= 2 && <Pagination.Item id={pageNumber + 1} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber + 1}</Pagination.Item>}
                                        {pageNumber == 1 && <Pagination.Item id={pageNumber + 2} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber + 2}</Pagination.Item>}
                                    </>}
                                </> : null}
                                {screenWidth < 768 ? <>
                                    {pageNumber >= (catalogueData.totalpages - 2) ? <>
                                        {pageNumber <= (catalogueData.totalpages - 1) && <Pagination.Item id={pageNumber + 1} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber + 1}</Pagination.Item>}
                                        {pageNumber == (catalogueData.totalpages - 2) && <Pagination.Item id={catalogueData.totalpages} onClick={(e) => handlePageNumber(e.target.id)}>{catalogueData.totalpages}</Pagination.Item>}

                                    </> : <> <Pagination.Ellipsis />
                                        <Pagination.Item id={catalogueData.totalpages} onClick={(e) => handlePageNumber(e.target.id)}>{catalogueData.totalpages}</Pagination.Item></>}
                                </> : <>
                                    {pageNumber >= (catalogueData.totalpages - 3) ? <>
                                        {pageNumber <= (catalogueData.totalpages - 1) && <Pagination.Item id={pageNumber + 1} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber + 1}</Pagination.Item>}
                                        {pageNumber <= (catalogueData.totalpages - 2) && <Pagination.Item id={pageNumber + 2} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber + 2}</Pagination.Item>}
                                        {pageNumber == (catalogueData.totalpages - 3) && <Pagination.Item id={catalogueData.totalpages} onClick={(e) => handlePageNumber(e.target.id)}>{catalogueData.totalpages}</Pagination.Item>}

                                    </> : <>
                                        <Pagination.Item id={pageNumber + 1} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber + 1}</Pagination.Item>
                                        <Pagination.Item id={pageNumber + 2} onClick={(e) => handlePageNumber(e.target.id)}>{pageNumber + 2}</Pagination.Item>
                                        <Pagination.Ellipsis />
                                        <Pagination.Item id={catalogueData.totalpages} onClick={(e) => handlePageNumber(e.target.id)}>{catalogueData.totalpages}</Pagination.Item>
                                    </>}
                                </>}

                                <Pagination.Next onClick={(e) => handlePageNumber((pageNumber + 1))}
                                    disabled={catalogueData.hasnext ? (catalogueData.hasnext ? false : true) : true} />
                            </Pagination>
                            <div className='gopage_div'>
                                <p>Go to page</p>
                                <form onSubmit={(e) => e.preventDefault()}>
                                    <input type="number" value={goto} className='gopage' required onChange={(e) => setGoto(e.target.value)} />
                                    <button type='button' className='bg-transparent p-0' onClick={() => {
                                        handlePageNumber(goto)
                                        setGoto('')
                                    }}>Go &gt;</button>
                                </form>
                            </div>
                        </div></> : <h5 className='mt-4 mb-5'>No Data Found</h5>}

                </div>
            </div >
        </div >
    )
}
